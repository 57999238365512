import { Box, Flex } from '@chakra-ui/react';
import SingleImage from '@components/Card/SingleImage';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import SimpleButton from '@msr/components/Buttons/SimpleButton';
import MsrSectionTitle from '@msr/components/Headings/MsrSectionTitle';
import SingleLineText from '@msr/components/UI/SingleLineText';
import { render } from 'datocms-structured-text-to-html-string';

/**
 *
 * @param {Object} sectionContent
 * @returns
 */
const Sadhana = ({ sectionContent, lang }) => {
  return (
    <Box p="60px 20px">
      <MsrSectionTitle
        pt="0"
        mt="0"
        titleObj={sectionContent[0]}
        w={{
          base: 'auto',
          md: '515px',
          lg: '600px',
        }}
      />
      <Flex
        direction={{ base: 'column', md: 'row' }}
        justifyContent="center"
        pt={{ base: 0, md: '20px' }}
        pb="20px"
        gridColumnGap={{ md: '20px', lg: '30px' }}
      >
        <Box alignSelf="flex-start">
          <SingleImage sectionContent={sectionContent[1]} my="0" px="0" />
        </Box>
        <Flex
          direction="column"
          pt={{ base: '20px', md: '0' }}
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Box>
            <SingleLineText
              sectionContent={sectionContent[2]}
              fontSize="26px"
              fontWeight="600"
              color="yellow.100"
              pt="0"
              mt="0"
              ml="0"
              mb="0"
              lineHeight={{ base: '1.2', md: '0.75' }}
              placeContent="flex-start"
            />
            <Box
              color="blue.200"
              maxW={{ base: '340px', md: '420px', lg: '340px' }}
              fontFamily={'FedraSansStd-book'}
              mt={{ base: '20px', md: '0', lg: '30px' }}
              lineHeight={{ base: '1.5', md: '1.3', lg: '1.5' }}
            >
              <StructuredTextParser
                str={render(sectionContent[3]?.body)}
                lang={lang}
              />
            </Box>
          </Box>
          <SimpleButton
            style={{
              ...sectionContent[4],
              buttonStyle: 'MSR-Orange-Transparent',
            }}
            lang={lang}
            mt={{ base: '40px', md: '0' }}
            mb="0"
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default Sadhana;
