/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/**
 *
 * @param {Object} sectionContent
 * @returns
 */
const HomeYaksha = ({ sectionContent, lang }) => {
  const dayCard = sectionData => {
    return (
      <>
        <Box
          className="msr-event-text"
          as="h3"
          color="yellow.100"
          fontSize="26px"
        >
          {sectionData?.text}
        </Box>
      </>
    );
  };

  const dayDetail = sectionData => {
    return (
      <>
        <Box
          as="p"
          fontFamily="FedraSansStd-book"
          fontSize="16px"
          color="#C1C3DD"
          pt="15px"
          m="0 auto"
          w={{ base: '90%', lg: '80%' }}
        >
          {sectionData?.text}
        </Box>
      </>
    );
  };

  const dayArtist = sectionData => {
    return (
      <>
        <Box
          as="p"
          fontSize={{ base: '22px', lg: '26px' }}
          fontWeight="600"
          pt="15px"
          lineHeight="30px"
          color="white"
        >
          {sectionData?.text}
        </Box>
      </>
    );
  };

  return (
    <>
      <Box bgColor="black" p="60px 20px">
        <Box
          // height={{ base: '1200px', lg: '1100px' }}
          background={{
            base: `url('${sectionContent[4]?.mobileImage?.url}') no-repeat center 22%`,
            md: `url('${sectionContent[4]?.image?.url}') no-repeat center`,
            lg: `url('${sectionContent[4]?.image?.url}') no-repeat center 50%`,
          }}
          backgroundSize={{ base: 'unset', md: '100%', lg: '75%', xl: 'unset' }}
        >
          <Box color="yellow.100" textAlign="center" m="0 auto">
            <Box
              as="h2"
              fontSize={{ base: '36px', lg: '48px' }}
              fontWeight="bold"
              textAlign="center"
              lineHeight="110%"
              fontFamily="FedraSerifAStdBook"
            >
              {sectionContent[0]?.text}
            </Box>
            <Box
              fontFamily="FedraSerifAStdBook"
              fontSize={{ base: '22px', lg: '26px' }}
              fontWeight="bold"
              lineHeight="110%"
            >
              {sectionContent[1]?.text}
            </Box>
            <Box textAlign="center">
              <StructuredTextParser
                str={render(sectionContent[2]?.body)}
                textAlign="center-align"
                color="white.50"
                fontFamily="FedraSansStd-book"
                fontSize={{ base: '16px', md: '18px', lg: '16px' }}
                maxW="500px"
                width={{ base: '280px', md: '60%', lg: '100%' }}
                mx="auto"
                lang={lang}
              />
            </Box>
            <Box
              fontSize={{ base: '22px', md: '26px' }}
              color="white"
              lineHeight="110%"
              mt="16px"
              fontWeight="600"
              width={{ base: '280px', md: '100%' }}
              textAlign="center"
              mx="auto"
            >
              {sectionContent[3]?.text}
            </Box>
          </Box>

          <Box
            display="flex"
            flexWrap="wrap"
            maxW="1036px"
            m={{
              base: '325px auto 0 auto',
              md: '580px auto 0 auto',
              lg: '600px auto 0 auto',
              xl: '750px auto 0 auto',
            }}
            justifyContent="center"
            gridGap="45px"
          >
            <Box
              fontFamily="FedraSerifAStdBook"
              textAlign="center"
              maxW={{ base: '240px', lg: '230px' }}
            >
              {dayCard(sectionContent[5])}
              {dayDetail(sectionContent[6])}
              {dayArtist(sectionContent[7])}
            </Box>
            <Box
              fontFamily="FedraSerifAStdBook"
              textAlign="center"
              maxW={{ base: '240px', sm: '240px', md: '200px', lg: '230px' }}
            >
              {dayCard(sectionContent[8])}
              {dayDetail(sectionContent[9])}
              {dayArtist(sectionContent[10])}
            </Box>
            <Box
              fontFamily="FedraSerifAStdBook"
              textAlign="center"
              maxW={{ base: '240px', sm: '240px', md: '180px', lg: '220px' }}
            >
              {dayCard(sectionContent[11])}
              {dayDetail(sectionContent[12])}
              {dayArtist(sectionContent[13])}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HomeYaksha;
