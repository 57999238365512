/* Built In Imports */

/* External Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
// Components
import SectionUI from '@msr/Common/SectionUI';
import StyleConfig from '@msr/Common/StyleConfig';
import ArtistsLive from '@msr/Sections/ArtistsLive';
import { AtHomeTitleSection } from '@msr/Sections/AtHomeTitleSection';
import { AttendInPersonBanner } from '@msr/Sections/AttendInPersonBanner';
import { CelebrationsGlimpses } from '@msr/Sections/CelebrationsGlimpses';
import CelebrationsYaksha from '@msr/Sections/CelebrationsYaksha';
import { ChantSection } from '@msr/Sections/ChantSection';
import EventScheduleSection from '@msr/Sections/EventScheduleSection';
import LiveStreamVideo from '@msr/Sections/LiveStreamVideo';
import MsrQuoteSection from '@msr/Sections/MsrQuoteSection';
import PastPerformances from '@msr/Sections/PastPerformances';
import { ProgramLocationDetails } from '@msr/Sections/ProgramLocationDetails';
import SadhanaKitSection from '@msr/Sections/SadhanaKitSection';
import { SeatingLayout } from '@msr/Sections/SeatingLayout';
import AboutThevaramSection from '@msr/Sections/Thevaram/AboutThevaramSection';
import SongDownloadSection from '@msr/Sections/Thevaram/SongDownloadSection';
import SongSection from '@msr/Sections/Thevaram/SongSection';
import StoryOfNayanmars from '@msr/Sections/Thevaram/StoryOfNayanmars';
import TestimonialThevaram from '@msr/Sections/Thevaram/TestimonialThevaram';
import ThevaramIntroSection from '@msr/Sections/Thevaram/ThevaramIntroSection';
import { ThevaramHeaderBanner } from '@msr/Sections/ThevaramHeaderBanner';
import TvChannelsDesc from '@msr/Sections/TvChannelsDesc';
import TvChannelsDetails from '@msr/Sections/TvChannelsDetails';
import { VideoTopHeader } from '@msr/Sections/VideoTopHeader';
import YakshaPerfArtist from '@msr/Sections/YakshaPerfArtist';
import YakshaSection from '@msr/Sections/YakshaSection';
import MsrDownloadsTab from '@msr/components/Tabs/MsrDownloadsTab';
import Annadanam from '@msr/components/UI/Home/Annadanam';
import Attend from '@msr/components/UI/Home/Attend';
import Banner from '@msr/components/UI/Home/Banner';
import Benefits from '@msr/components/UI/Home/Benefits';
import Celebrations from '@msr/components/UI/Home/Celebrations';
import GraceOfYoga from '@msr/components/UI/Home/GraceOfYoga';
import HomeYaksha from '@msr/components/UI/Home/HomeYaksha';
import Info from '@msr/components/UI/Home/Info';
import Rudrakshadiksha from '@msr/components/UI/Home/Rudrakshadiksha';
import Sadhana from '@msr/components/UI/Home/Sadhana';
import ShivaChants from '@msr/components/UI/Home/Shivachants';
import ShivaStories from '@msr/components/UI/Home/Shivastories';
import CelebrationsDesc from '../Sections/CelebrationsDesc';

/**
 * Renders Render MSR Sections
 *
 * @param {object} sectionContent - Data
 * @param {string} region - Region of Page
 * @param {string} lang - Language of Page
 * @param sectionContent.region
 * @param sectionContent.lang
 * @param sectionContent.pType
 * @param sectionContent.csrData
 * @param sectionContent.pageData
 * @returns {ReactElement} Render MSR Sections
 */

const RenderSections = ({
  section,
  region,
  lang,
  pType,
  csrData,
  pageData,
}) => {
  const { sectionId } = section;
  // Render section list home page

  if (sectionId === 'msr-header-banner') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        minH={{ base: 'auto', lg: '600px' }}
      >
        <Banner
          sectionContent={section.sectionContent}
          lang={lang}
          bgImg={section?.styleConfig?.bgImage}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'msr-quote-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <MsrQuoteSection sectionContent={section.sectionContent} lang={lang} />
      </StyleConfig>
    );
  } else if (sectionId === 'celebrations-glimpses') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <CelebrationsGlimpses section={section} lang={lang} />
      </StyleConfig>
    );
  } else if (sectionId === 'msr-intro-section') {
    return (
      <Info
        section={section}
        region={region}
        sectionContent={section.sectionContent}
        lang={lang}
      />
    );
  } else if (sectionId === 'athome-title-section') {
    // ! Need to review hardcode
    return (
      <AtHomeTitleSection
        section={section}
        sectionContent={section.sectionContent}
        lang={lang}
      />
    );
  } else if (sectionId === 'msr-celebrations-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <Celebrations sectionContent={section.sectionContent} lang={lang} />
      </StyleConfig>
    );
  } else if (sectionId === 'celebrations-desc') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <CelebrationsDesc sectionContent={section.sectionContent} lang={lang} />
      </StyleConfig>
    );
  } else if (sectionId === 'msr-benefits-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <Benefits sectionContent={section.sectionContent} lang={lang} />
      </StyleConfig>
    );
  } else if (sectionId === 'msr-ways-to-attend') {
    return section.sectionContent.length ? (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <Attend sectionContent={section.sectionContent} lang={lang} />
      </StyleConfig>
    ) : null;
  }  else if (sectionId === 'msr-sadhguru-app') {
    return section.sectionContent.length ? (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <Attend sectionContent={section.sectionContent} lang={lang} />
      </StyleConfig>
    ) : null;
  } else if (sectionId === 'msr-goy-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <GraceOfYoga sectionContent={section.sectionContent} lang={lang} />
      </StyleConfig>
    );
  } else if (sectionId === 'msr-downloads-tab') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <MsrDownloadsTab
          sectionContent={section.sectionContent}
          lang={lang}
          pType={pType}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'msr-yaksha-artists') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <HomeYaksha sectionContent={section.sectionContent} lang={lang} />
      </StyleConfig>
    );
  } else if (sectionId === 'celebrations-yaksha-artists') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <CelebrationsYaksha
          sectionContent={section.sectionContent}
          lang={lang}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'msr-rudraksha-diksha') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <Rudrakshadiksha sectionContent={section.sectionContent} lang={lang} />
      </StyleConfig>
    );
  } else if (sectionId === 'msr-annadanam-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <Annadanam sectionContent={section.sectionContent} lang={lang} />
      </StyleConfig>
    );
  } else if (sectionId === 'msr-iiis-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <Sadhana
          sectionContent={section.sectionContent}
          key={nanoid(5)}
          lang={lang}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'msr-sadhana-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <Sadhana
          sectionContent={section.sectionContent}
          key={nanoid(5)}
          lang={lang}
        />
      </StyleConfig>
    );
    // Render section list home page end
  } else if (sectionId === 'msr-shivachants-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <ShivaChants sectionContent={section.sectionContent} lang={lang} />
      </StyleConfig>
    );
  } else if (sectionId === 'msr-shivastories-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <ShivaStories sectionContent={section.sectionContent} lang={lang} />
      </StyleConfig>
    );
  } else if (sectionId === 'attend-in-person-banner') {
    // Render section list attend in person start
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <AttendInPersonBanner section={section} key={nanoid(5)} lang={lang} />
      </StyleConfig>
    );
  } else if (sectionId === 'seating-layout-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <SeatingLayout section={section} key={nanoid(5)} lang={lang} />
      </StyleConfig>
    );
  } else if (sectionId === 'program-location-details') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <ProgramLocationDetails section={section} key={nanoid(5)} lang={lang} />
      </StyleConfig>
    );
    // Render section list attend in person end
  } else if (sectionId === 'video-top-header') {
    // Midnight meditation
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <VideoTopHeader section={section} key={nanoid(5)} lang={lang} />
      </StyleConfig>
    );
  } else if (sectionId === 'tv-channels-desc') {
    // Render section list  tv - channels - details
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pt="0"
        pb="0"
        mb="0"
        section={section}
      >
        <TvChannelsDesc
          sectionContent={section.sectionContent}
          key={nanoid(5)}
          lang={lang}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'tv-channels-details') {
    // Render section list  tv - channels - details
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <TvChannelsDetails
          sectionContent={section.sectionContent}
          key={nanoid(5)}
          lang={lang}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'chant-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <ChantSection
          sectionContent={section.sectionContent}
          key={nanoid(5)}
          lang={lang}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'sadhana-kit-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <SadhanaKitSection
          sectionContent={section.sectionContent}
          key={nanoid(5)}
          lang={lang}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'live-stream-video') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB={{ base: '40px', lg: '60px' }}
        mB="0"
        section={section}
      >
        <LiveStreamVideo
          sectionContent={section.sectionContent}
          key={nanoid(5)}
          lang={lang}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'event-schedule-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <EventScheduleSection
          sectionContent={section.sectionContent}
          key={nanoid(5)}
          lang={lang}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'thevaram-header-banner') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <ThevaramHeaderBanner
          sectionContent={section.sectionContent}
          region={region}
          lang={lang}
          key={nanoid(5)}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'about-thevaram-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        bgSize="auto"
        section={section}
      >
        <AboutThevaramSection
          sectionContent={section.sectionContent}
          key={nanoid(6)}
          lang={lang}
          region={region}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'thevaram-intro-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        bgSize="auto"
        section={section}
      >
        <ThevaramIntroSection
          sectionContent={section.sectionContent}
          key={nanoid(6)}
          lang={lang}
          region={region}
        />
      </StyleConfig>
    );
  } else if (/^song\-[a-zA-Z]{2,5}\-section$/.test(sectionId)) {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        bgSize="auto"
        section={section}
      >
        <SongSection
          sectionContent={section.sectionContent}
          key={nanoid(6)}
          lang={lang}
          region={region}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'song-download-section') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        width="100%"
        pB="0"
        mB="0"
        m="0"
        bgSize="auto"
        section={section}
      >
        <SongDownloadSection
          sectionContent={section.sectionContent}
          key={nanoid(6)}
          lang={lang}
          region={region}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'story-of-nayanmars') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <StoryOfNayanmars
          sectionContent={section.sectionContent}
          key={nanoid(6)}
          lang={lang}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'birth-of-thevaram') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        bgSize="auto"
        section={section}
      >
        <TestimonialThevaram
          sectionContent={section.sectionContent}
          key={nanoid(6)}
          lang={lang}
          csrData={csrData}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'artists-live') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <ArtistsLive
          sectionContent={section.sectionContent}
          key={nanoid(6)}
          lang={lang}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'past-perfomances') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <PastPerformances
          sectionContent={section.sectionContent}
          key={nanoid(6)}
          lang={lang}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'about-yaksha') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <YakshaSection
          sectionContent={section.sectionContent}
          key={nanoid(6)}
          lang={lang}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'yaksha-performing-artists') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        style={section.styleConfig}
        pT="0"
        pB="0"
        mB="0"
        section={section}
      >
        <YakshaPerfArtist
          sectionContent={section.sectionContent}
          key={nanoid(6)}
          lang={lang}
        />
      </StyleConfig>
    );
  } else if (sectionId === 'sambandar') {
    return <></>;
  } else if (sectionId === 'nayanar') {
    return <></>;
  } else if (sectionId === 'appar') {
    return <></>;
  } else {
    // return null;
    return (
      <Box as="div" display="block" placeContent="center">
        {section?.sectionContent && section?.sectionContent?.length
          ? section?.sectionContent?.map((secCon, i) => {
              return (
                <StyleConfig
                  id={section?.sectionId}
                  key={section?.sectionId}
                  style={section?.styleConfig}
                  section={section}
                  // pT="0"
                  pB={{ base: '0', md: '15px' }}
                  // mB="0"
                >
                  <SectionUI
                    key={i}
                    sectionContent={secCon}
                    section={section}
                    region={region}
                    language={lang}
                    pType={pType}
                    csrData={csrData}
                    pageData={pageData}
                  />
                </StyleConfig>
              );
            })
          : null}
      </Box>
    );
  }
};

export default RenderSections;
