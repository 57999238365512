/* Built in Imports */
import NextLink from 'next/link';
/* External Imports */
import { Box, Flex, Heading, Link, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
/* Internal Imports */
import StructuredTextParser from '@msr/Utility/StructuredTextParser';
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import SimpleButton from '@msr/components/Buttons/SimpleButton';
import MediaEmbed from '@msr/components/Embeds/MediaEmbed';
/* Styles */
/* Services */
/**
 * Renders ThevaramIntroSection Component
 * @param {Object} sectionContent - dato
 * @param {string} region - region of the page
 * @param {string} lang - Language of the page
 * @returns {ReactElement} ThevaramIntroSection Component
 */

export const ThevaramIntroSection = ({ sectionContent, region, lang }) => {
  const embedObj = {
    embedUrl: sectionContent[0].videoUrl,
    source: 'youtube',
  };

  return <>
    <Box
      maxW="1440px"
      m={{ base: '10px', md: '40px', lg: '0 auto' }}
      height="auto"
      pb="20px"
    >
      <Box
        maxW="1230px"
        height="auto"
        m="0 auto"
        pb="50px"
        display="grid"
        placeItems="center"
      >
        <Flex
          flexDir={{ base: 'column', md: 'column', lg: 'row' }}
          alignItems="center"
          m={{ base: '0 20px', md: '0 20px', lg: '0' }}
        >
          <Box
            as="article"
            maxW="500px"
            mt={{ base: '24px', md: '0' }}
            mr={{ base: '0', md: '0', lg: '54px' }}
          >
            <Heading
              as="h1"
              fontStyle="normal"
              fontWeight="350"
              fontSize="48px"
              lineHeight="120%"
              color="#8D4D2D"
              fontFamily="FedraSerifAStdBook"
            >
              {sectionContent[0].title}
            </Heading>
            <Heading
              as="h3"
              fontWeight="600"
              fontSize="26px"
              lineHeight="127.5%"
              fontFamily="FedraSerifAStdBook"
            >
              {sectionContent[0].subtext}
            </Heading>
            <Text
              as="p"
              fontWeight="400"
              fontSize="16px"
              lineHeight="150%"
              fontStyle="normal"
              mt="10px"
              fontFamily="FedraSansStd-book"
              ml={{ base: '0', md: '10px' }}
              className="thevaram-intro-section"
            >
              <style>
                {`
                .thevaram-intro-section{
                  p {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                  }
                }
                `}
              </style>
              <StructuredTextParser
                str={render(sectionContent[0].description)}
                p="0"
                lang={lang}
              />
            </Text>
            <Box pt="39px">
              <Flex
                flexDir={{ md: 'row', base: 'column' }}
                alignItems={{ base: 'start', md: 'center' }}
              >
                {/* <Button
                  width="209px"
                  height="56px"
                  borderRadius="5px"
                  background="#FFA735"
                  border={0}
                  fontFamily="FedraSansStd-book"
                  fontWeight="600"
                  fontSize="16px"
                  lineHeight="20px"
                  color="black"
                >
                  
                </Button> */}
                <SimpleButton
                  style={sectionContent[0]?.cta[0]}
                  region={region}
                  lang={lang}
                  mb="0"
                  mt="0"
                  fontSize="16px"
                  width="209px"
                  lineHeight="20px"
                />
                <Flex height="56px" maxH="46px" mt={{ base: '20px', md: 0 }}>
                  {sectionContent[0].musicAvailableIn.map(
                    (musicLink, index) => {
                      let url = '';
                      if (musicLink.source == 'applemusic') {
                        url =
                          // 'https://images.sadhguru.org/mahashivratri/wp-content/uploads/2020/02/itunes-copy-2.png';
                          `${config.staticPath}/d/46272/1669975196-itunes-copy-2.png`;
                      } else if (musicLink.source == 'youtube') {
                        url =
                          // 'https://images.sadhguru.org/mahashivratri/wp-content/uploads/2020/02/youtube-black-copy-7.png';
                          `${config.staticPath}/d/46272/1669975199-youtube-black-copy-7.png`;
                      } else {
                        url =
                          // 'https://images.sadhguru.org/mahashivratri/wp-content/uploads/2020/02/spotify-copy-2.png';
                          `${config.staticPath}/d/46272/1669975190-spotify-copy-2.png`;
                      }
                      return (
                        <NextLink href={musicLink.embedUrl} passHref key={index} legacyBehavior>
                          <Link
                          // mr={{
                          //   base: '33.63px',
                          //   md: '33.63px',
                          // }}
                          >
                            <LazyLoadImageComponent
                              ml={{ base: '0', sm: '33.63px', md: '33.63px' }}
                              mr={{ base: '33.63px', sm: '0', md: '0' }}
                              maxH="43px"
                              src={url}
                            />
                          </Link>
                        </NextLink>
                      );
                    }
                  )}
                  <LazyLoadImageComponent
                    src=""
                    ml={{ base: 0, md: '33.63px' }}
                    maxH="43px"
                  />
                  <LazyLoadImageComponent src="" ml="33.63px" maxH="43px" />
                  <LazyLoadImageComponent src="" ml="33.63px" maxH="43px" />
                </Flex>
              </Flex>
            </Box>
          </Box>
          <Box
            pt={{ base: '30.01px', md: '63px', lg: '0' }}
            width={{ base: '100%', md: '100%', lg: '500px', xl: '579px' }}
          >
            <MediaEmbed sectionContent={embedObj} margin="0" pxBase="0" />
            {/* <Box
              as="iframe"
              minW={{ base: '100%', md: '100%', lg: '579px' }}
              width="400"
              height={{ base: '181px', md: '415px', lg: '326px' }}
              src={sectionContent[0].videoUrl}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            /> */}
          </Box>
        </Flex>
      </Box>
    </Box>
  </>;
};

export default ThevaramIntroSection;
