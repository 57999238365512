/* Built In Imports */

/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import MsrSectionTitle from '@msr/components/Headings/MsrSectionTitle';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/* Services */

/**
 *
 * @param root0
 * @param root0.sectionContent
 * @param root0.lang
 * @returns
 */
export const ChantSection = ({ sectionContent, lang }) => {
  return (
    <Box
      pt={{ base: '30px', md: '0' }}
      pb={{ base: '30px', md: '80px' }}
      px={{ base: '5%', md: '14%' }}
    >
      <MsrSectionTitle titleObj={sectionContent[0]} />
      <StructuredTextParser
        str={render(sectionContent[1]?.body)}
        theme="light"
        m="0 auto"
        lang={lang}
      />
    </Box>
  );
};
