/* Built In Imports */
import { useState } from 'react';

/* External Imports */
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Button, Collapse } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';
import MsrEventScheduleCardGroup from '@msr/components/Card/MsrEventScheduleCardGroup';
import MsrSectionTitle from '@msr/components/Headings/MsrSectionTitle';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/* Services */

/**
 * @returns
 */
const EventScheduleSection = ({ sectionContent, lang }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <Box d="flex" justifyContent="center" mt="40px">
        <Button
          type="submit"
          className="orange-button"
          mb="0"
          mt="30px"
          display="block"
          fontWeight="500"
          fontFamily="FedraSansStd-medium"
          fontSize="16px"
          bgColor="yellow.100"
          h="auto"
          minH="50px"
          border="none"
          textAlign="center"
          padding="18px 20px"
          borderRadius="5px"
          color="black"
          textDecoration="none"
          width="90%"
          maxW="496px"
          _hover={{
            bg: 'fulvous',
          }}
          onClick={() => setOpen(!isOpen)}
        >
          {sectionContent[0]?.buttonText}
          {!isOpen ? (
            <ChevronDownIcon w={7} h={7} />
          ) : (
            <ChevronUpIcon w={7} h={7} />
          )}
        </Button>
      </Box>

      <Collapse in={isOpen} animateOpacity duration={100000}>
        <Box d="flex" flexDirection="column">
          <MsrSectionTitle titleObj={sectionContent[1]} />
          <MidContainer w="586px" width="100%" px={{ base: '16px', lg: 0 }}>
            <StructuredTextParser
              str={render(sectionContent[2]?.body)}
              region=""
              lang={lang}
              textAlign={sectionContent[2]?.style}
              theme="light"
              color="#fff7e9"
            />
          </MidContainer>
          <Box mt={{ base: '40px', md: '60px' }} mb="0">
            <MsrEventScheduleCardGroup
              sectionContent={sectionContent[3]}
              lang={lang}
            />
          </Box>
        </Box>
      </Collapse>
    </>
  );
};

export default EventScheduleSection;
