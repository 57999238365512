/* Built In Imports */
/* External Imports */
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import TvChannelsSubtitle from '@msr/components/FieldIds/TvChannelsSubtitle';
import MsrSectionTitle from '@msr/components/Headings/MsrSectionTitle';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/* Services */

/**
 *
 * @param root0
 * @param root0.sectionContent
 * @param root0.lang
 * @returns
 */
const TvChannelsDesc = ({ sectionContent, lang }) => {
  return (
    <Flex py={{ base: '34px', md: '60px' }} px="16px">
      <Box
        maxW="700px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems={{ base: 'left', md: 'center' }}
        textAlign={{ base: 'left', md: 'center' }}
        mx="auto"
      >
        <Box
          sx={{
            h1: {
              textAlign: { base: 'left', md: 'center' },
              pl: { base: '0', md: '10px' },
              maxW: { base: '225px', md: '100%' },
              m: { base: '0 0 15px 0', md: '0 auo' },
            },
          }}
        >
          <MsrSectionTitle titleObj={sectionContent[0]} as="h1" mt="0" />
        </Box>
        <Box
          sx={{
            h4: {
              textAlign: { base: 'left', md: 'center' },
            },
          }}
        >
          <TvChannelsSubtitle sectionContent={sectionContent[1]} />
        </Box>
        <Box
          sx={{
            p: {
              textAlign: { base: 'left', md: 'center' },
            },
          }}
        >
          <StructuredTextParser
            str={render(sectionContent[2]?.body)}
            region={''}
            lang={lang}
            tAlign={sectionContent.style}
            theme="light"
            textAlign="center"
            color="#CACEF3"
          />
        </Box>

        <Flex
          gridGap={{ base: '16px', md: '20px' }}
          maxWidth={{ base: '100%' }}
          placeContent="center"
          mt="20px"
        >
          <Box
            p={{ base: '10px', md: '20px' }}
            bgColor="rgba(255, 255, 255, 0.08)"
            borderRadius="7px"
            textAlign="center"
            width={{ base: '156px', md: '200px' }}
          >
            <Text
              as="h4"
              fontFamily="FedraSansStd-book"
              fontSize="18px"
              fontWeight={{ base: '600', md: '400' }}
              color="#FFA735"
              pb="10px"
            >
              Telecast in
            </Text>
            <Heading
              as="h2"
              fontFamily="FedraSerifAStdBook"
              fontSize="40px"
              color="#FFA735"
              pb={{ base: '0', md: '20px' }}
            >
              100+
            </Heading>
            <Text
              as="h4"
              fontFamily="FedraSansStd-book"
              fontSize={{ base: '14px', md: '18px' }}
              color="#CACEF3"
            >
              TV Channels
            </Text>
          </Box>
          <Box
            p={{ base: '10px', md: '20px' }}
            bgColor="rgba(255, 255, 255, 0.08)"
            borderRadius="7px"
            textAlign="center"
            width={{ base: '156px', md: '200px' }}
          >
            <Text
              as="h4"
              fontFamily="FedraSansStd-book"
              fontSize="18px"
              fontWeight={{ base: '600', md: '400' }}
              color="#FFA735"
              pb="10px"
            >
              Live <br /> Translation in
            </Text>
            <Heading
              as="h2"
              fontFamily="FedraSerifAStdBook"
              fontSize="40px"
              color="#FFA735"
              pb={{ base: '0', md: '20px' }}
            >
              14
            </Heading>
            <Text
              as="h4"
              fontFamily="FedraSansStd-book"
              fontSize={{ base: '14px', md: '18px' }}
              color="#CACEF3"
            >
              Indian and Global Language
            </Text>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default TvChannelsDesc;
