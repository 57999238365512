/* Built In Imports */
import { useRef, useState } from 'react';

/* External Imports */
import {
  Box,
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import SectionUI from '@msr/Common/SectionUI';
import BackupLiveStreamVideo from '@msr/Sections/BackupLiveStreamVideo';
import LiveStreamDate from '@msr/components/FieldIds/LiveStreamDate';
import LiveStreamTitle from '@msr/components/FieldIds/LiveStreamTitle';
import SubscriptionModal from '@msr/components/Modal/SubscriptionModal';

/* Services */

/**
 * @param sectionContent
 * @param lang
 * @returns
 */
const LiveStreamVideo = ({ sectionContent, lang = 'en' }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = useRef(null);
  const [messageTemp, setMessageTemp] = useState('');
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      {sectionContent?.map((secCon, i) => {
        if (secCon.fieldId === 'live-stream-title') {
          return <LiveStreamTitle sectionContent={secCon} key={i} />;
        } else if (secCon.fieldId === 'live-stream-date') {
          return <LiveStreamDate sectionContent={secCon} key={i} />;
        } else if (secCon._modelApiKey === 'media_embed') {
          return (
            <BackupLiveStreamVideo
              sectionContent={secCon}
              key={i}
              lang={lang}
            />
          );
        } else if (
          secCon._modelApiKey === 'simple_button' ||
          secCon._modelApiKey === 'newsletter_subscription_form'
        ) {
          if (secCon._modelApiKey === 'simple_button') {
            return (
              <Box display="flex" justifyContent="center">
                <Button
                  type="submit"
                  className="orange-button"
                  mb={'0'}
                  mt="20px"
                  display="block"
                  fontWeight={'500'}
                  fontFamily={'FedraSansStd-medium'}
                  fontSize={'16px'}
                  bgColor="yellow.100"
                  h={'auto'}
                  minH="50px"
                  border="none"
                  textAlign="center"
                  padding={'18px 20px'}
                  borderRadius="5px"
                  color="black"
                  textDecoration="none"
                  width={'auto'}
                  _hover={{
                    bg: 'fulvous',
                  }}
                  onClick={onOpen}
                >
                  {secCon?.buttonText}
                </Button>
                <Modal
                  finalFocusRef={finalRef}
                  isOpen={isOpen}
                  onClose={onClose}
                  isCentered
                  motionPreset="slideInBottom"
                  size={!messageTemp ? 'xl' : '4xl'}
                  blockScrollOnMount={true}
                  closeOnOverlayClick={false}
                  scrollBehavior={isMobile ? 'inside' : 'outside'}
                >
                  <ModalOverlay />
                  <ModalContent>
                    <SubscriptionModal
                      sectionContent={sectionContent[i + 1]}
                      messageT={messageTemp}
                      newsletters={{ MSRLivestream: 'MSRLivestream' }}
                      dataLayer={{
                        event: 'msr_vp_pushed',
                        virtual_path: '/msr_live_stream_vp_success',
                      }}
                    />
                  </ModalContent>
                </Modal>
              </Box>
            );
          }
        } else {
          return (
            <SectionUI
              key={i}
              sectionContent={secCon}
              region={''}
              language={lang}
            />
          );
        }
      })}
    </>
  );
};

export default LiveStreamVideo;
