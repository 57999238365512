/* Built In Imports */
/* External Imports */
import { Box, Flex, Show, VStack } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import SimpleButton from '@msr/components/Buttons/SimpleButton';
import SingleLineText from '@msr/components/UI/SingleLineText';
import CountdownTimer from '@msr/components/Utility/CountdownTimer';

/* Services */
/* Styles */

/**
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent
 * @param sectionContent.bgImg
 * @param sectionContent.lang
 * @returns
 */
const Banner = ({ sectionContent, bgImg, lang }) => {
  return (
    <Box pos="relative">
      <Box>
        <video
          id="background-video"
          autoPlay="autoplay"
          loop="loop"
          muted
          poster={bgImg?.url}
        >
          
        </video>
      </Box>
      <Flex
        pos="absolute"
        top="0"
        bottom="0"
        left="0"
        right="0"
        direction="column"
        justifyContent="center"
      >
        <VStack
          verticalAlign={'center'}
          p={{
            base: '10px 20px 40px',
            md: '60px 0',
            lg: '100px 0',
            '3xl': '450px 0',
          }}
        >
          <SingleLineText
            sectionContent={sectionContent[0]}
            color="white"
            fontSize={{ base: '26px', md: '48px' }}
            pt="0"
            fontFamily="FedraSerifAStdBook"
            fontWeight={{ base: '600', md: '500' }}
            lineHeight={{ base: '15px', md: '1' }}
            as="h1"
          />
          <SingleLineText
            sectionContent={sectionContent[1]}
            color="yellow.100"
            fontSize={{ base: '16px', md: '18px' }}
            pt="0"
            mt="0"
            fontFamily="FedraSansStd-book"
            fontWeight="400"
            lineHeight={{ base: '1px', md: '1.2' }}
          />
          <Box
            display="flex"
            flexDir={{ base: 'row', md: 'column' }}
            w={{ base: '100%', md: '80%' }}
            maxW="400px"
            justifyContent={{ base: 'center', md: 'unset' }}
            mt={{ base: '22px!important', md: 'inherit' }}
            sx={{
              '.embedBox': {
                width: { base: 'auto', md: '100%' },
                marginInlineStart: { base: '0', md: 'auto' },
                marginInlineEnd: { base: '0', md: 'auto' },
              },
            }}
          >
            <SingleLineText
              sectionContent={sectionContent[2]}
              color="white"
              fontSize={{ base: '16px', md: '26px' }}
              mt={{ base: '0', md: '25px' }}
              pt="0"
              mb="5px"
              fontFamily="FedraSansStd-book"
              fontWeight="700"
              lineHeight="1"
              as="h4"
              textAlign="center"
            />
            <SingleLineText
              sectionContent={sectionContent[3]}
              color="white"
              fontSize={{ base: '16px', md: '26px' }}
              mt="0"
              ml={{ base: '0', md: 'inherit' }}
              pt="0"
              fontFamily="FedraSansStd-book"
              fontWeight="700"
              lineHeight="1"
              as="h4"
              textAlign="center"
            />
          </Box>

          <SingleLineText
            sectionContent={sectionContent[4]}
            color="white"
            fontSize={{ base: '16px' }}
            mt={{ base: '-10px', md: '20px' }}
            pt="0"
            fontFamily="FedraSansStd-book"
            fontWeight="500"
            lineHeight="0.95"
            as="h4"
            textAlign="center"
          />

          <Show above="md">
            <SimpleButton
              style={sectionContent[5]}
              mb="0"
              mt="20px"
              lang={lang}
            />
          </Show>

          <Box
            mt={{
              md: '38px !important',
              lg: '47px !important',
            }}
          >
            <CountdownTimer
              targetDate={sectionContent[6]?.text}
              targetText={sectionContent[8].data}
            />
          </Box>
        </VStack>
      </Flex>
      <style jsx>
        {`
          #background-video {
            width: 100vw;
            max-height: 614px;
            object-fit: cover;
            position: static;
            left: 0;
            right: 0;
            top: 0px;
            bottom: 0;
            z-index: 99;
          }

          @media (max-width: 1024px) {
            #background-video {
              height: 100%;
            }
          }

          @media (max-width: 800px) {
            #background-video {
              height: 520px;
            }
          }

          @media (max-width: 480px) {
            #background-video {
              height: 55vh;
            }
          }

          @media (max-width: 320px) {
            #background-video {
              height: 50vh;
            }
          }
        `}
      </style>
    </Box>
  );
};

export default Banner;
