/* Built In Imports */
import { useRef, useState } from 'react';

/* External Imports */
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import CardGrid from '@msr/components/Card/CardGrid';
import SubscriptionModal from '@msr/components/Modal/SubscriptionModal';
import SingleLineText from '@msr/components/UI/SingleLineText';
import SimpleButton from '@msr/components/Buttons/SimpleButton';

/* Services */

/**
 *
 * @param {Object} sectionContent
 * @returns
 */
const Celebrations = ({ sectionContent, lang }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = useRef(null);
  const [messageTemp, setMessageTemp] = useState('');
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box bg="blue.500" w="100%" h="auto" p="40px 20px">
      {/* <Flex
        width={"full"} justify={"center"} marginBottom={{ base: "60px", sm: "60px", md: "100px" }
        } >
        <LazyLoadImageComponent
          src={isMobile ? (sectionContent[0]?.mobileImage?.url || sectionContent[0]?.mobileImage?.url) : sectionContent[0]?.image?.url}
          alt=""
          title=""
        />
      </Flex > */}
      <Box p="0px 20px">
        <Flex
          direction={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'flex-start', md: 'center' }}
          fontWeight="400"
          mx="auto"
        >
          <SingleLineText
            sectionContent={sectionContent[0]}
            fontSize={{ base: '64px', md: '120px' }}
            color="yellow.100"
            fontWeight="600"
            lineHeight={{ base: '71.36px', md: '133.8px' }}
            pt="0"
            ml={{ base: '0' }}
            placeContent={{ base: 'left', md: 'flex-end' }}
            w="100%" // Needed for 4k screens
          />
          <SingleLineText
            sectionContent={sectionContent[1]}
            fontSize={lang === 'ta' ? '27px' : '40px'}
            color="yellow.100"
            width={{ base: 'auto', lg: '300px' }}
            lineHeight={{ base: '48px', md: '50px' }}
            mt="0"
            ml={{ base: '0', md: '10px' }}
            pt={{ base: '0', md: '20px' }}
            placeContent={{ base: 'left', md: 'flex-start' }}
            w="100%" // Needed for 4k screens
          />
        </Flex>

        {
          sectionContent[2]?._modelApiKey === 'structured_text' && (
            <Box
              fontFamily="FedraSansStd-book"
              fontSize="16px"
              fontWeight="normal"
              color="blue.200"
              line-height="150%"
              mx="auto"
              textAlign={{ base: 'left', md: 'center' }}
              maxW={{ base: '500px', md: '600px', xl: '700px' }}
            >
              <StructuredTextParser
                str={render(sectionContent[2]?.body)}
                theme="light"
                lang={lang}
                textAlign={{ base: 'left', md: 'center' }}
              />
            </Box>
          )
        }
        {
          sectionContent[2]?._modelApiKey === 'card_info4_column_group' && (
            <Box mt={{ base: '0', lg: '30px' }}>
              <CardGrid
                sectionContent={sectionContent[2]}
                title={{ fontSize: '18px', color: 'yellow.100' }}
                desc={{ color: 'white.50', fontStyle: 'italic' }}
              />
            </Box>
          )
        }
        {
          sectionContent[3]?._modelApiKey === 'card_info4_column_group' && (
            <Box mt={{ base: '0', lg: '30px' }}>
              <CardGrid
                sectionContent={sectionContent[3]}
                title={{ fontSize: '18px', color: 'yellow.100' }}
                desc={{ color: 'white.50', fontStyle: 'italic' }}
              />
            </Box>
          )
        }

        {
          sectionContent[4]?._modelApiKey === 'card_info4_column_group' && (
            <Box mt={{ base: '0', lg: '30px' }}>
              <CardGrid
                sectionContent={sectionContent[4]}
                title={{ fontSize: '18px', color: 'yellow.100' }}
                desc={{ color: 'white.50', fontStyle: 'italic' }}
                pageType="celebrations"
              />
            </Box>
          )
        }
        {
          sectionContent[4]?._modelApiKey === 'simple_button' && (
            <Box
              display="flex"
              mt={{ base: '0', lg: '30px' }}
              justifyContent="center"
            >
              <SimpleButton
                style={sectionContent[4]}
                region={''}
                lang={lang}
              />
            </Box>
            )
        }
        {
          sectionContent[5]?._modelApiKey === 'simple_button' && (
            <Box
              display="flex"
              mt={{ base: '0', lg: '30px' }}
              justifyContent="center"
            >
              <Box display="flex" justifyContent="center">
                <Button
                  type="submit"
                  className="orange-button"
                  mb={'0'}
                  mt="20px"
                  display="block"
                  fontWeight={'500'}
                  fontFamily={'FedraSansStd-medium'}
                  fontSize={'16px'}
                  bgColor="yellow.100"
                  h={'auto'}
                  minH="50px"
                  border="none"
                  textAlign="center"
                  padding={'18px 20px'}
                  borderRadius="5px"
                  color="black"
                  textDecoration="none"
                  width={'auto'}
                  _hover={{
                    bg: 'fulvous',
                  }}
                  onClick={onOpen}
                >
                  {sectionContent[5]?.buttonText}
                </Button>
                <Modal
                  finalFocusRef={finalRef}
                  isOpen={isOpen}
                  onClose={onClose}
                  isCentered
                  motionPreset="slideInBottom"
                  size={!messageTemp ? 'xl' : '4xl'}
                  blockScrollOnMount={false}
                  closeOnOverlayClick={false}
                  scrollBehavior={isMobile ? 'inside' : 'outside'}
                >
                  <ModalOverlay />
                  <ModalContent>
                    <SubscriptionModal
                      sectionContent={sectionContent[5]}
                      messageT={messageTemp}
                      newsletters={{ MSRLivestream: 'MSRLivestream' }}
                      dataLayer={{
                        event: 'msr_vp_pushed',
                        virtual_path: '/msr_live_stream_vp_success',
                      }}
                    />
                  </ModalContent>
                </Modal>
              </Box>
            </Box>
          )
        }

      </Box>

      {/* <Highlights sectionContent={sectionContent[3]} /> */}
    </Box >
  );
};

export default Celebrations;
