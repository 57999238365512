'use client';

import { Box, Flex, useBreakpointValue, useMediaQuery } from '@chakra-ui/react';
import SimpleButton from '@msr/components/Buttons/SimpleButton';
import SingleLineText from '@msr/components/UI/SingleLineText';
import React from 'react';

/**
 *
 * @param {Object} sectionContent
 * @returns
 */
const GraceOfYoga = ({ sectionContent, lang }) => {
  const imageUrl = (typeof window !== 'undefined') && useBreakpointValue(
    {
      base: sectionContent[5]?.mobileImage?.url,
      md: sectionContent[5]?.image?.url,
    },
    {
      fallback: 'md',
    },
    {
      ssr: false,
    }
  );

  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  return (
    <>
      {isLargerThan768 ? (
        <Flex
          width="100%"
          height="auto"
          backgroundSize="cover"
          backgroundPosition={{
            md: '76% center',
            lg: '70% center',
            xl: 'center',
          }}
          backgroundRepeat="no-repeat"
          backgroundImage={'url(' + imageUrl + ')'}
          py={{ md: '25px', lg: '30px', xl: '30px' }}
          minH={{ md: '100%', '3xl': '900px' }}
          alignItems="center"
        >
          <RenderInfo sectionContent={sectionContent} lang={lang} />
        </Flex>
      ) : (
        <Flex
          width="100%"
          height="auto"
          backgroundSize="cover"
          backgroundPosition={{
            base: '100% top',
          }}
          backgroundRepeat="no-repeat"
          backgroundImage={'url(' + imageUrl + ')'}
          py={{ md: '25px', lg: '30px', xl: '30px' }}
          minH={{ base: '790px' }}
          alignItems="center"
        >
          {/* <LazyLoadImageComponent
            src={imageUrl}
            alt={sectionContent[5]?.mobileImage?.alt}
            title={sectionContent[5]?.mobileImage?.title}
            width={'100%'}
          /> */}
          <Box p="334px 10px 0 10px">
            <RenderInfo sectionContent={sectionContent} lang={lang} />
          </Box>
        </Flex>
      )}
    </>
  );
};

const RenderInfo = ({ sectionContent, lang }) => {
  return (
    <Flex
      justifyContent="center"
      align="center"
      width={{ base: '100%', lg: '53%' }}
      direction="column"
      ml={{ base: '0', lg: '3%' }}
      p={{ base: '10px' }}
    >
      <SingleLineText
        sectionContent={sectionContent[0]}
        fontSize={{ base: '32px', lg: '50px' }}
        fontFamily="FedraSansStd-A-medium, serif"
        color={'#FDD26A'}
        textAlign={'center'}
        lineHeight={{ base: '32px', lg: '50px' }}
        pt={'0'}
      />
      <SingleLineText
        sectionContent={sectionContent[1]}
        fontSize={{ base: '18px', lg: '26px' }}
        fontFamily="FedraSansStd-book, sans-serif"
        color={'#FDECC3'}
        textAlign={'center'}
        lineHeight={{ base: '18px', lg: '26px' }}
        pt={'0'}
      />
      <Box width={{ base: '100%', lg: '85%' }}>
        <SingleLineText
          sectionContent={sectionContent[2]}
          fontFamily="FedraSansStd-book, sans-serif"
          fontSize={{ base: '15px', lg: '22px' }}
          fontStyle="italic"
          textAlign={'center'}
          color={'#FFDB83'}
          lineHeight={{ base: '24px', lg: '30px' }}
          pt={{ base: '10px', lg: '20px' }}
          pb={{ base: '0px', lg: '20px' }}
        />
      </Box>
      <SingleLineText
        sectionContent={sectionContent[3]}
        fontFamily="FedraSansStd-A-medium, serif"
        fontSize={{ base: '26px', lg: '32px' }}
        color={'#FDECC3'}
        textAlign={'center'}
        lineHeight={{ base: '26px', lg: '32px' }}
        pt={{ base: '10px', lg: '10px' }}
      />
      <SimpleButton
        style={{
          ...sectionContent[4],
          buttonStyle: 'MSR-LightRed',
        }}
        lang={lang}
        mt={{ base: '30px', md: '30px', lg: '40px' }}
        mb={{ base: '10px' }}
      />
      <style jsx global>
        {`
          .goyHr hr {
            border-top: 1px solid #a98c5e;
          }
        `}
      </style>
    </Flex>
  );
};

export default GraceOfYoga;
