/* Built In Imports */
/* External Imports */
import { Box, Flex, Heading } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import AccordionSection from '@msr/components/Accordions/Accordion';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/* Services */

/**
 *
 * @param root0
 * @param root0.sectionContent
 * @param root0.lang
 * @returns
 */
const TvChannelsDetails = ({ sectionContent, lang }) => {
  return (
    <Flex
      py={{ base: '34px', md: '60px' }}
      px="16px"
    >
      <Box
        maxW="790px"
        w="full"
        display="flex"
        flexDirection="column"
        mx="auto"
      >
        <Heading as="h2" color="#fff" fontSize="40px">
          <StructuredTextParser
            str={render(sectionContent[0]?.body)}
            mt="0"
            fontSize={{ base: '26px', md: '40px' }}
            theme="light"
            lang={lang}
          />
        </Heading>
        <AccordionSection sectionContent={sectionContent[1]?.content} />
      </Box>
    </Flex>
  );
};

export default TvChannelsDetails;
