/* Built In Imports */
import NextLink from 'next/link';
import { useEffect } from 'react';

/* External Imports */
import { Box, Link, TabList, Tabs } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';
import { refineUrlDomain } from '@msr/components/Utility/utils';

/**
 * @param sectionContent.sectionContent
 * @param {object} sectionContent
 * @param sectionContent.lang
 * @param sectionContent.pType
 * @returns MsrDownloadsTab Component
 */
const MsrDownloadsTab = ({ sectionContent, lang, pType }) => {
  const typeArr = [
    'shiva-images-wallpapers',
    'download-shiva-songs-mp3',
    'mahashivratri-wishes-greetings-images',
    'shiva-videos',
    'shiva-ebooks',
  ];
  const pTypeIndex = typeArr.indexOf(pType);

  useEffect(() => {
    // To move the tab scroll position for download links
    document.querySelector('.tabsMobScroll').scrollLeft = document
      .querySelector('.active')
      ?.getBoundingClientRect()?.left;
  }, []);

  return (
    <MidContainer w="full">
      <Tabs fontFamily="FedraSansStd-book">
        <TabList borderBottom="transparent">
          <Box
            fontSize="16px"
            color="#fff"
            w={{ base: '100%', lg: '90%', xl: '1140px' }}
            display="flex"
            mx="auto"
            lineHeight="94px"
            whiteSpace="nowrap"
            overflowX={{ base: 'scroll', lg: 'hidden' }}
            justifyContent="space-between"
            textTransform="uppercase"
            className="tabsMobScroll"
          >
            {sectionContent[0].cards?.map((card, i) => {
              return (
                <Box
                  key={'tab' + i}
                  display="flex"
                  id={'tab' + i}
                  className="item"
                  _hover={{
                    borderBottom: '4px solid #FFA735',
                  }}
                  borderBottom={
                    pTypeIndex === i
                      ? '4px solid #FFA735'
                      : '4px solid transparent'
                  }
                  p="0 10px"
                >
                  <NextLink
                    href={refineUrlDomain(card?.buttonLink, '', lang)}
                    passHref
                    scroll={false}
                    legacyBehavior>
                    <Link
                      id={'tabLink' + i}
                      color="#fff"
                      display="flex"
                      alignItems="center"
                      fontFamily="FedraSansStd-medium"
                      bgImg={
                        pTypeIndex === i
                          ? card.mobileThumbnail?.url
                          : card.thumbnail?.url
                      }
                      bgRepeat="no-repeat"
                      bgPos="0% 50%"
                      _hover={{
                        textDecoration: 'none',
                        color: 'yellow.100',
                        backgroundImage: `${card.mobileThumbnail?.url}`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '0% 50%',
                      }}
                      scroll={false}
                    >
                      <Box
                        as="span"
                        pl="34px"
                        color={pTypeIndex === i ? 'yellow.100' : 'inherit'}
                        className={pTypeIndex === i ? 'active' : ''}
                      >
                        {card.title}
                      </Box>
                    </Link>
                  </NextLink>
                </Box>
              );
            })}
          </Box>
        </TabList>
      </Tabs>
      <style>{`
        #msr-downloads-tab {
          margin-bottom:80px; 
          justify-content: center;
        }
        @media (min-width: 768px) and (max-width: 991px) {
          #msr-downloads-tab {
            margin-bottom:40px; 
          }
          #msr-downloads-tab .tabsMobScroll > .item {
            min-width: 20%;
            text-align: center;
            justify-content: center;
            scroll-margin: 30px;
            scroll-snap-align: end;
            scroll-snap-stop: normal;
          }
        }

        @media (min-width: 600px) and (max-width: 767px) {
          #msr-downloads-tab {
            margin-bottom: 40px;
          }
          #msr-downloads-tab .tabsMobScroll > .item {
            min-width: 30%;
            text-align: center;
            justify-content: center;
            scroll-margin: 30px;
            scroll-snap-align: end;
            scroll-snap-stop: normal;
          }
        }

        @media (max-width: 599px) {
          #msr-downloads-tab {
            margin-bottom: 40px;
          }
          #msr-downloads-tab .tabsMobScroll > .item {
            min-width: 40%;
            text-align: center;
            justify-content: center;
            scroll-margin: 30px;
            scroll-snap-align: end;
            scroll-snap-stop: normal;
          }
        }
      `}</style>
    </MidContainer>
  );
};

export default MsrDownloadsTab;
