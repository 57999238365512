/* Built In Imports */

/* External Imports */
import { Box, Flex } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
// import StructuredTextContent from '@components/UI/StructuredTextContent';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import SimpleButton from '@msr/components/Buttons/SimpleButton';
import SingleLineText from '@msr/components/UI/SingleLineText';

/* Services */

/**
 * Renders the Card Info 3 Card component
 * @param {Object} sectionContent - Data for section
 * @returns {ReactElement} CardGrid Card component
 */

const CardGrid = ({ sectionContent, title, sessions, desc, pageType, lang }) => {
  return (
    <Box
      mx="auto"
      maxW={{
        base: '302px',
        sm: '480px',
        md: '690px',
        lg: '850px',
        xl: '1170px',
      }}
    >
      <Flex
        gridGap="15px"
        mx="auto"
        flexWrap={{ base: 'wrap', lg: 'nowrap' }}
        pt={'20px'}
        justifyContent="center"
        alignItems={pageType === 'attend' ? 'stretch' : 'flex-start'}
      >
        {sectionContent?.cards?.map((secCon, _) => {
          return (
            <Box
              key={nanoid()}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="flex-end"
              flexShrink="0"
              flexGrow="0"
              flexBasis={{
                base: '100%',
                md: '45%',
                lg: sectionContent?.cards?.length > 3 ? '24%' : '33%',
              }}
              pl="0.625rem"
              pr="0.625rem"
              pb={{ base: '50px', lg: 0 }}
              mx="auto"
              w={{
                base: '100%',
                sm: sectionContent?.cards?.length > 3 ? '230px' : '330px',
                md: sectionContent?.cards?.length > 3 ? '250px' : '350px',
                lg: sectionContent?.cards?.length > 3 ? '270px' : '370px',
              }}
              position="relative"
            >
              <LazyLoadImageComponent
                src={secCon.thumbnail?.url}
                alt={secCon.thumbnail?.alt}
                title={secCon.thumbnail?.title}
                pb={'15px'}
              />

              <Box>
                <SingleLineText
                  sectionContent={{ text: secCon.title }}
                  fontSize={title.fontSize}
                  color={title.color}
                  pt="0"
                  mt="0"
                  mr="0"
                  ml="0"
                  fontWeight="600"
                  textAlign="center"
                  lineHeight="1.2"
                  wordBreak="break-word"
                />
                {secCon.sessions && (
                  <SingleLineText
                    sectionContent={{ text: secCon.sessions }}
                    fontSize={sessions.fontSize}
                    color={sessions.color}
                    pt="0"
                    mt="0"
                    mr="0"
                    ml="0"
                    textAlign="center"
                    lineHeight="1.2"
                    fontFamily="FedraSansStd-book"
                  />
                )}
                <Box
                  color={desc.color}
                  pt={desc.pt || 0}
                  fontFamily="FedraSansStd-book"
                  maxW={{ base: '250px', md: 'inherit' }}
                >
                  <StructuredTextParser
                    str={render(secCon.description)}
                    mt="0"
                    textAlign="center"
                    fontStyle={desc.fontStyle}
                    lang={lang}
                  />
                </Box>
              </Box>

              {secCon.buttonLink && (
                <Box mt={'auto'}>
                  <SimpleButton
                    style={{
                      buttonStyle: 'MSR-Orange-Transparent',
                      buttonText: secCon.buttonText,
                      linkUrl: secCon.buttonLink
                    }}
                    lang={lang}
                    mt="30"
                    mb="0"
                  />
                </Box>
              )}
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
};

export default CardGrid;
