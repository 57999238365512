/* External Imports */
import { Box, Flex, Heading } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import config from '@config';
import SimpleButton from '@msr/components/Buttons/SimpleButton';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/* Services */

/**
 *
 * @returns
 */
export const SeatingLayout = ({ section, lang }) => {
  return (
    <Flex py={{ base: '40px', lg: '70px' }} px={{ base: '15px', lg: '140px' }}>
      <Box maxW="1140px" w="full" mx="auto" display={'flex'} justifyContent={'center'}>
        <Box
          // justifyContent={{ base: 'center', md: 'space-between' }}
          // flexFlow={{ base: 'column', md: 'row' }}
          alignItems="center"
          // gridGap={{ base: '20px', md: '70px', lg: '100px' }}
        >
          {/* <Flex
            flexFlow="row"
            alignItems={{ base: 'center', md: 'initial' }}
          > */}
            <Box
              mb={{ base: '10px', md: '' }} textAlign={'center'}
            >
              <img
                alt="divider"
                title="divider"
                src={`${config.imgPath}/d/46272/1664431985-drums-vector.png`}
              />
            </Box>
            <Heading
              as="h2"
              fontSize={{ base: '26px', md: '40px' }}
              color="#fff"
              pb={{ base: '20px', md: '15px' }}
              textAlign={'center'}
            >
              {section?.sectionContent?.[0].text}
            </Heading>
            {section?.sectionContent?.map(item => (
              <Box w={{ base: 'auto', lg: '350px' }}>
                <StructuredTextParser
                  str={render(item.body)}
                  theme="light"
                  fontFamily="FedraSansStd-book"
                  fontSize="16px"
                  mt="10px"
                  lineHeight={2.5}
                  pl={4}
                  lang={lang}
                />
              </Box>
            ))}
            <Box w={{ base: 'auto', lg: '350px' }}>
              <SimpleButton
                style={section?.sectionContent[2]}
                region={''}
                lang={lang}
              />
            </Box>
          {/* </Flex> */}
          <Flex mt={{ base: 14, md: 0 }} w={{ base: 'auto', lg: 'auto' }} textAlign={'center'}>
            <img
              src={section?.sectionContent[3]?.image?.url}
              alt={section?.sectionContent[3]?.image?.alt}
              title={section?.sectionContent[3]?.image?.title}
            />
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};