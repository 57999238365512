/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import MsrSectionTitle from '@msr/components/Headings/MsrSectionTitle';
import { refineUrlDomain } from '@msr/components/Utility/utils';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/**
 *
 * @param {Object} sectionContent
 * @returns
 */
const ShivaStories = ({ sectionContent, lang, page }) => {
  const sectionTitleConfig = sectionContent[0];
  sectionTitleConfig.color = 'white';
  const shivaPostList = sectionContent[1];
  const contentType = {
    video: `${config.imgPath}/d/46272/1664431945-video-play.png`,
    audio: `${config.imgPath}/d/46272/1664431963-songs.png'`,
    article: `${config.imgPath}/d/46272/1664431942-article.png`,
    poem: `${config.imgPath}/d/46272/1664431938-poem.png`,
  };

  const NextArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top="50%"
        zIndex="10"
        cursor="pointer"
        bgSize="20px"
        w="46px"
        h="46px"
        boxShadow="0 0 7px 0 rgb(0 0 0 / 13%)"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        bgImage={`url(${config.imgPath}/d/46272/1664373600-vector-2.png)`}
        right={{ base: '-10px', md: '-40px' }}
        onClick={onClick}
        display={{ base: 'none', md: 'block' }}
      ></Box>
    );
  };
  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top="50%"
        zIndex="10"
        cursor="pointer"
        bgSize="20px"
        boxShadow="0 0 7px 0 rgb(0 0 0 / 13%)"
        w="46px"
        h="46px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        bgImage={`url(${config.imgPath}/d/46272/1664373605-vector-3.png)`}
        left={{ base: '-10px', md: '-40px' }}
        onClick={onClick}
        display={{ base: 'none', md: 'block' }}
      />
    );
  };

  const settings = {
    dots: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 11000,
    lazyLoad: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    centerMode: false,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1170,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
    customPaging: function (i) {
      return (
        <Text
          cursor="pointer"
          display="inline-block"
          w="12px"
          h="12px"
          m="0px 8px"
          bgColor="#c4c4c4"
          borderRadius="50%"
        />
      );
    },
  };
  return <>
    <Box
      id="stories-container"
      background="blue.400"
      border="none"
      p="60px 20px"
      sx={{
        h2: {
          mb: '0',
        },
      }}
    >
      <MsrSectionTitle
        mt="0"
        pt="0"
        titleObj={sectionTitleConfig}
        w={{ base: '250px', md: '400px' }}
      />
      <Box
        px={{ base: '0', md: '50px' }}
        py="20px"
        maxW={{ base: '100%', lg: '1162px' }}
        m="0 auto"
        id="stories-slider"
      >
        <Slider {...settings} m="0">
          {shivaPostList?.shivaPosts?.map(cardDetail => {
            return cardDetail && (
              <Box
                overflow="hidden"
                display="flex"
                flexWrap="wrap"
                pb={{ base: '5px', md: '0' }}
              >
                <Box
                  height="423px"
                  width={{ base: '95%' }}
                  margin="0 auto 20px auto"
                  background="blue.600"
                  position="relative"
                >
                  <NextLink
                    href={refineUrlDomain(
                      cardDetail?.urlAlias,
                      '',
                      lang,
                      'shiva'
                    )}
                    passHref
                    legacyBehavior>
                    <Link
                      position="relative"
                      zIndex="1"
                      cursor={cardDetail?.urlAlias ? 'cursor' : 'auto'}
                    >
                      <LazyLoadImageComponent
                        src={cardDetail?.cardImage?.url}
                        alt={cardDetail?.cardImage?.alt}
                        title={cardDetail?.cardImage?.title}
                        width="100%"
                        objectPosition="top"
                        height="228px"
                      />
                    </Link>
                  </NextLink>
                  <Box
                    bottom="0"
                    width="100%"
                    backgroundSize="cover"
                    padding="20px 20px 20px 20px"
                    zIndex="10"
                    right="0"
                    left="0"
                  >
                    <NextLink
                      href={refineUrlDomain(
                        cardDetail?.urlAlias,
                        '',
                        lang,
                        'shiva'
                      )}
                      passHref
                      legacyBehavior>
                      <Link
                        position="relative"
                        zIndex="1"
                        cursor={cardDetail?.urlAlias ? 'cursor' : 'auto'}
                        _hover={{
                          textDecoration: 'none',
                        }}
                      >
                        <Box
                          fontFamily="FedraSerifAStdBook"
                          fontSize="26px"
                          lineHeight="1.25"
                          color="white.50"
                          marginBottom="20px"
                          fontWeight="600"
                          className="truncate-text lines-2"
                        >
                          {cardDetail?.title}
                        </Box>
                      </Link>
                    </NextLink>
                    <Box
                      fontFamily="FedraSansStd-book"
                      fontSize="16px"
                      lineHeight="1.5"
                      color="blue.700"
                      marginBottom="20px"
                      width="85%"
                      className="truncate-text"
                    >
                      {cardDetail.summary}
                    </Box>
                    <Box position="absolute" bottom="20px" right="20px">
                      <LazyLoadImageComponent
                        src={contentType[cardDetail.types]}
                        alt={cardDetail.types}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Slider>
      </Box>

      {/* <Box
        color="white"
        fontFamily={'FedraSansStd-book'}
        fontSize="18px"
        textAlign="center"
        pt={{ base: '38px', md: '10px' }}
        className="white-link"
      >
        {StructuredTextContent(sectionContent[2]?.body)}
      </Box> */}
      <Box
        className="white-link"
        fontSize="18px"
        pt={{ base: '15px', md: '15px' }}
      >
        <StructuredTextParser
          str={render(sectionContent[2]?.body)}
          fontFamily={'FedraSansStd-book'}
          textAlign="center"
          lang={lang}
        />
      </Box>
    </Box>
    <style jsx global>
      {`
        .slick-dots {
          margin-bottom: 0px;
        }
        .slick-dots li.slick-active p {
          background-color: #f5ac3e;
        }
        .white-link p {
          color: white;
          font-size: 18px !important;
        }
        .white-link a {
          text-decoration: underline;
          color: white !important;
        }
        .white-link strong {
          text-decoration: none;
          color: white !important;
        }
        @media (max-width: 768px) {
          #stories-slider .slick-list .slick-active {
            width: 300px !important;
          }
        }
        @media (max-width: 320px) {
          #stories-container {
            padding: 60px 0;
          }
        }
      `}
    </style>
  </>;
};

export default ShivaStories;
