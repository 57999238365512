import { Flex, Text, VStack } from '@chakra-ui/react';
import { useCountdown } from '@msr/hooks/useCountDown';

export default function CountdownTimer({ targetDate, targetText }) {
  const [days, hours, minutes] = useCountdown(targetDate);
  const fontFedraAStd = 'FedraSerifAStdBook';

  return (
    <Flex gridGap="15px">
      <VStack>
        <Text
          color="white"
          fontSize={{ base: '36px', md: '40px' }}
          fontWeight="600"
          lineHeight="1.2"
          fontFamily={fontFedraAStd}
          as="h2"
        >
          {days}
        </Text>
        <Text
          color="yellow.100"
          fontSize={{ base: '16px', md: '18px' }}
          fontWeight="600"
          lineHeight="1.2"
          fontFamily={fontFedraAStd}
          as="h6"
        >
          {targetText[0].Days}
        </Text>
      </VStack>
      <VStack ml="10px">
        <Text
          color="white"
          fontSize={{ base: '36px', md: '40px' }}
          fontWeight="600"
          lineHeight="1.2"
          fontFamily={fontFedraAStd}
          as="h2"
        >
          {hours}
        </Text>
        <Text
          color="yellow.100"
          fontSize={{ base: '16px', md: '18px' }}
          fontWeight="600"
          lineHeight="1.2"
          fontFamily={fontFedraAStd}
          as="h6"
        >
          {targetText[0].Hours}
        </Text>
      </VStack>
      <VStack ml="10px">
        <Text
          color="white"
          fontSize={{ base: '36px', md: '40px' }}
          fontWeight="600"
          lineHeight="1.2"
          fontFamily={fontFedraAStd}
          as="h2"
        >
          {minutes}
        </Text>
        <Text
          color="yellow.100"
          fontSize={{ base: '16px', md: '18px' }}
          fontWeight="600"
          lineHeight="1.2"
          fontFamily={fontFedraAStd}
          as="h6"
        >
          {targetText[0].Minutes}
        </Text>
      </VStack>
    </Flex>
  );
}
