'use client';

/* Built In Imports */
import React from 'react';

/* External Imports */
import { Box, Flex, useBreakpointValue, useMediaQuery } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import SimpleButton from '@msr/components/Buttons/SimpleButton';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/**
 *
 * @param {Object} sectionContent
 * @returns
 */
const Rudrakshadiksha = ({ sectionContent, lang }) => {
  const imageUrl = (typeof window !== 'undefined') && useBreakpointValue(
    {
      base: sectionContent[0]?.mobileImage?.url,
      md: sectionContent[0]?.image?.url,
    },
    {
      fallback: 'md',
    },
    {
      ssr: false,
    }
  );
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  return (
    <>
      {isLargerThan768 ? (
        <Flex
          width="100%"
          height="auto"
          backgroundSize="cover"
          backgroundPosition={{
            md: '80% center',
            lg: '75% center',
            xl: '100% center',
          }}
          backgroundRepeat="no-repeat"
          backgroundImage={'url(' + imageUrl + ')'}
          py={{ md: '40px', lg: '60px' }}
          minH={{ '3xl': '900px' }}
          alignItems="center"
        >
          <RenderInfo sectionContent={sectionContent} />
        </Flex>
      ) : (
        <Box bgColor={'black.600'}>
          <LazyLoadImageComponent
            src={imageUrl}
            alt={sectionContent[0]?.mobileImage?.alt}
            title={sectionContent[0]?.mobileImage?.title}
            width={'100%'}
          />
          <Box p="0 20px 50px 20px" mt={{ base: '-75px', md: '0' }}>
            <RenderInfo sectionContent={sectionContent} lang={lang} />
          </Box>
        </Box>
      )}
    </>
  );
};

const RenderInfo = ({ sectionContent, lang }) => {
  return (
    <Flex
      justifyContent="center"
      align="center"
      maxW={{ base: '100%', md: '40%', lg: '40%' }}
      direction="column"
      marginLeft={{ base: '0', md: '3%', lg: '8%', xl: '12%' }}
    >
      <LazyLoadImageComponent
        src={sectionContent[1]?.image?.url}
        alt={sectionContent[1]?.image?.alt}
        title={sectionContent[1]?.image?.title}
        width={{ base: '70%', sm: '40%', md: '75%', lg: '85%', xl: '90%' }}
      />
      <Box
        justifyContent="center"
        width={{ base: '90%', sm: '60%', md: '80%', lg: '96%' }}
        fontFamily={'FedraSansStd-book'}
        lineHeight={{ base: '1.5', md: '1.4', lg: '1.5' }}
        color="white"
        pt={{ base: '30px', md: '20px', lg: '30px' }}
        sx={{
          p: {
            textAlign: { base: 'left', md: 'center' },
            mb: '15px',
          },
        }}
      >
        <StructuredTextParser
          str={render(sectionContent[2]?.body)}
          textAlign="center"
          maxW="400px"
          theme="light"
          lang={lang}
        />
      </Box>
      <SimpleButton
        style={{
          ...sectionContent[3],
          buttonStyle: 'MSR-Orange-RightIcon',
          imgPath: '/img/right-arrow.png',
          imgWidth: '12px',
          imgPT: '3px',
        }}
        lang={lang}
        mt={{ base: '30px', md: '10px', lg: '30px' }}
        mb="0"
      />
    </Flex>
  );
};

export default Rudrakshadiksha;
