import { Box, Flex, Heading } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
/* Internal Imports */
import SingleImage from '@components/Card/SingleImage';
import StructuredTextParser from '@msr//Utility/StructuredTextParser';
/**
 *
 * @returns
 */
export const ThevaramHeaderBanner = ({ sectionContent, region, lang }) => {
  // consoleLog(sectionContent[0]);
  return (
    <Flex
      // bgImage="url('https://iso-videos.sadhguru.org/videos/30-sec-without-text-for-web.mp4')"
      // py={{ base: '30px', md: '57px' }}
      // px={{ base: '0', md: '140px' }}
      display="flex"
      flexDir="column"
      maxW="100%"
      mx="auto"
      bgSize="60%"
      maxH={{ base: '286px', md: '432px', lg: '610px' }}
      overflow="hidden"
      justifyContent="center"
      pos="relative"
    >
      <Box
        as="video"
        id="background-video"
        autoPlay="autoplay"
        loop="loop"
        muted
        width="100%"
        objectFit="cover"
      >
        <source src={sectionContent[1].text} class="jsx-2b5b191830f9fb0d" />
      </Box>
      <Box
        className="page-wrap"
        pos="absolute"
        top={0}
        right="0"
        width="100%"
        height="100%"
        left={0}
        bottom={0}
        display="grid"
        placeItems="center"
      >
        <Box
        //  width="361px" margin="auto" height="252.93px"
        >
          <Heading
            as="h2"
            fontSize={{ base: '26px', md: '48px' }}
            color="#FFA735"
            fontWeight="500"
            textAlign="center"
            mb="16px"
          >
            {sectionContent[0]?.titleText}
          </Heading>
          <SingleImage
            w={{ base: '172.81px', md: '315px', lg: '361px' }}
            h={{ base: '121.07px', md: '221px', lg: '252.93px' }}
            maxWidth="361px"
            sectionContent={sectionContent[0]}
            region={region}
            lang={lang}
          />
          <StructuredTextParser
            str={render(sectionContent[1]?.body)}
            theme="light"
            fontSize="18px"
            color="#C1C3DD"
            textAlign="center"
            mx="auto"
            lang={lang}
          />
        </Box>
      </Box>
      <style jsx global>{`
        #athome-content-section {
          padding: 0;
        }
        @media (max-width: 599px) {
          #midnight-sadhana-section,
          #athome-content-section {
            padding: 0 15px;
          }
        }
        @media (min-width: 600px) and (max-width: 959px) {
          #midnight-sadhana-section,
          #athome-content-section {
            padding: 0 15px;
          }
        }
      `}</style>
    </Flex>
  );
};
