/* Built In Imports */
import dynamic from 'next/dynamic';

/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@msr//Utility/StructuredTextParser';
import LiveStreamTitle from '@msr/components/FieldIds/LiveStreamTitle';
const MediaEmbed = dynamic(() => import('@msr/components/Embeds/MediaEmbed'));

/* Services */

/**
 *
 * @param root0
 * @param root0.section
 * @param root0.lang
 * @returns
 */
export const VideoTopHeader = ({ section, lang }) => {
  return (
    <Box
      color="#fff"
      maxW="1300px"
      display="flex"
      mx="auto"
      justifyContent="space-between"
      flexDir="column"
      pb={{ base: '20px', md: '50px' }}
      alignItems="center"
    >
      {/* <SectionTitle titleObj={section?.sectionContent[0]} /> */}
      <LiveStreamTitle sectionContent={section?.sectionContent[0]} />

      <MediaEmbed sectionContent={section?.sectionContent[1]} />
      <StructuredTextParser
        str={render(section.sectionContent[2]?.body)}
        mt="0"
        pt="0"
        theme="light"
        fontWeight="bold"
        fontSize="18px"
        px="15px"
        lang={lang}
      />
    </Box>
  );
};
