/* Built In Imports */

/* External Imports */
import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import SingleImage from '@components/Card/SingleImage';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import SimpleButton from '@msr/components/Buttons/SimpleButton';
import SingleLineText from '@msr/components/UI/SingleLineText';

/**
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent
 * @param sectionContent.lang
 * @returns
 */
const Annadanam = ({ sectionContent, lang }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      justifyContent="center"
      flexWrap="wrap"
      alignItems="center"
      maxW="1440px"
      margin="auto"
      p={{ base: '0 0 60px 0', md: '40px', lg: '60px' }}
    >
      <Box flex="2" minW={{ base: '1', md: '350px' }}>
        <SingleImage
          sectionContent={sectionContent[0]}
          my="0"
          w={{ base: '80%', md: '100%' }}
          maxWidth="550px"
        />
      </Box>
      <Flex
        margin={{ base: '10px 0px 0', sm: '10px 0px 0', lg: '10px 40px 0' }}
        // maxW={{ base: '270px', md: '337px' }}
        flex={{ md: '1', lg: '1', xl: '2' }}
        w={{ base: 'auto', md: '370px' }}
        direction="column"
        justifyContent={{ base: 'center', md: 'start' }}
        alignItems={{ base: 'center', md: 'start' }}
        p={{ base: '20px 6px 0 10px', md: '0 20px 0 10px' }}
        flexBasis={{ md: '80%', lg: '50%', xl: '40%' }}
      >
        <SingleLineText
          sectionContent={sectionContent[1]}
          fontSize={{
            base: lang === 'ta' ? '27px' : '40px',
            md: '24px',
            lg: '40px',
          }}
          fontWeight="600"
          color="black.400"
          pt="0"
          mt="0"
          // textAlign={{ base: 'center', md: 'left' }}
          textAlign={sectionContent[5] != undefined ? 'left' : 'center'}
          lineHeight="1.2"
        />
        <Box
          p={{
            base: '10px 0',
            md: '10px 20px 0 10px',
            lg: '30px 20px 0 10px',
          }}
          color="black.400"
          alignSelf={sectionContent[5] != undefined ? 'left' : 'center'}
          fontFamily={'FedraSansStd-book'}
          maxW={{ base: '100%', md: '340px', lg: '430px' }}
        >
          <StructuredTextParser
            str={render(sectionContent[2]?.body)}
            textAlign={sectionContent[5] != undefined ? 'left' : 'center'}
            theme="dark"
            lang={lang}
          />
        </Box>
        <Box
          p={{ base: '20px 0 0 0', md: '30px 0 0 10px' }}
          alignSelf={sectionContent[5] != undefined ? 'left' : 'center'}
        >
          <SimpleButton
            style={{
              ...sectionContent[3],
              buttonStyle: 'MSR-Orange-with-Arrow',
            }}
            lang={lang}
            w="246px"
            mt="0"
            mb="0"
          />
        </Box>
      </Flex>
      {sectionContent[5] != undefined && (
        <Box flex="1" mt={{ base: '30px', lg: '0' }}>
          <Box
            as="h3"
            textAlign="center"
            fontWeight="600"
            fontStyle="normal"
            fontSize="22px"
            lineHeight="140%"
            my="10px"
          >
            {sectionContent[4]?.text}
          </Box>

          {isMobile && (
            <Link href="upi://pay?pa=thenkailayabhaktiperavai@cub&pn=THENKAILAYABHAKTIPERAVAI&cu=INR">
              <Box mt="10px">
                <Box
                  pt="10px"
                  bg="#ffffff"
                  textAlign="center"
                  display={{ base: 'block', md: 'none' }}
                  m="0"
                  p="0"
                >
                  <Text
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight="350"
                    pt="15px"
                    // lineHeight="140%"
                  >
                    Click the QR Code
                  </Text>
                </Box>
                <SingleImage
                  sectionContent={sectionContent[5]}
                  px="0"
                  my="0"
                  w={{ base: '245px', md: '238px' }}
                  h={{ base: '255px', md: '219px' }}
                  maxWidth={{ base: '245px', md: '238px' }}
                  mx="0"
                />
              </Box>
            </Link>
          )}

          {!isMobile && (
            <Box mt="10px">
              <Box
                pt="10px"
                bg="#ffffff"
                textAlign="center"
                display={{ base: 'block', md: 'none' }}
                m="0"
                p="0"
              >
                <Text
                  fontSize="18px"
                  fontStyle="normal"
                  fontWeight="350"
                  pt="15px"
                  // lineHeight="140%"
                >
                  Click the QR Code
                </Text>
              </Box>
              <SingleImage
                sectionContent={sectionContent[5]}
                px="0"
                my="0"
                w={{ base: '245px', md: '238px' }}
                h={{ base: '255px', md: '219px' }}
                maxWidth={{ base: '245px', md: '238px' }}
                mx="0"
              />
            </Box>
          )}

          <Box
            as="p"
            textAlign="center"
            fontWeight="500"
            my="10px"
            color="#282728"
          >
            {sectionContent[6]?.text}
          </Box>
          <SingleImage
            sectionContent={sectionContent[7]}
            my={{ base: '15px', md: '20px' }}
            maxWidth="170px"
          />
          <Box className="AnnadanamText" my="5px" maxW="225px" mx="auto">
            <StructuredTextParser
              str={render(sectionContent[8]?.body)}
              textAlign="center"
              theme="dark"
              lang={lang}
              lineHeight="140%"
              fontFamily="FedraSansStd-book"
              fontSize="12px"
            />
          </Box>
          <style>
            {`
            .AnnadanamText{
              font-style: normal;
              font-weight: 400; 
              color:#8A7F66; 
            }
            .AnnadanamText a{
              color:#CF4520;
              text-decoration: underline;
              }
            `}
          </style>
        </Box>
      )}
    </Flex>
  );
};

export default Annadanam;