/* Built In Imports */
/* External Imports */
import { Box, Link } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/* Services */

/**
 * Renders Social Media Icon Component
 * @param {Object} sectionContent - data from api
 * @param {string} region - region of the page
 * @param {string} lang - language of the page
 * @returns {ReactElement} GoogleMap component
 */
const SocialMediaIcon = ({
  sectionContent,
  maxW,
  width,
  margin,
  height,
}) => {
  return (
    <Box display="flex" justifyContent="center" maxW={maxW}>
      <Link href={sectionContent?.linkUrl} target="_blank">
        <LazyLoadImageComponent
          src={sectionContent?.image?.url}
          alt={sectionContent?.image?.alt}
          title={sectionContent?.image?.title}
          width={width ? width : '60px'}
          height={height ? height : '60px'}
          margin={margin}
          m={4}
        />
      </Link>
    </Box>
  );
};

export default SocialMediaIcon;
