/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */

/* Services */

/**
 * Renders the ArtistsLive Section component
 * @param {Object} sectionContent - Data for section
 * @param {string} region - Region for the Section
 * @param {string} lang - Language for the Section
 * @returns {ReactElement} - ArtistsLive Section Component
 */
export const MsrQuoteSection = ({ sectionContent, lang }) => {
  return (
    <>
      <Box
        bgImg={{
          base: sectionContent[0].mobileImage?.url,
          lg: sectionContent[0].image?.url,
        }}
        bgRepeat="no-repeat"
        bgPosition={{ base: 'center', xl: 'right' }}
        alt={sectionContent.image?.alt}
        title={sectionContent.image?.title}
        w="100%"
        h={{ base: '520px', lg: '400px', xl: '475px' }}
      />
    </>
  );
};

export default MsrQuoteSection;
