/* Built In Imports */
/* External Imports */
import { Box, Flex } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
/* Internal Imports */
import StructuredTextParser from '@msr/Utility/StructuredTextParser';
/* Components */
import SingleImage from '@components/Card/SingleImage';

/**
 * Renders AboutThevaramSection Component
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent dato
 * @param {string} region region of the page
 * @param {string} lang language
 * @param sectionContent.region
 * @param sectionContent.lang
 * @returns {ReactElement} AboutThevaramSection Component
 */
const AboutThevaramSection = ({ sectionContent, region, lang }) => {
  return (
    <Box
      pt={{ base: '20px', lg: '40px' }}
      maxW="1440px"
      m={{ base: '10px', md: '40px', lg: '0 auto' }}
    >
      <Box
        maxW="1106px"
        m="0 auto"
        height="auto"
        display="grid"
        placeItems="center"
      >
        <Flex
          flexDir={{ base: 'column', md: 'row' }}
          alignItems="flex-start"
          m={{ base: '0 20px', md: '0' }}
        >
          <Box
            mr={{ base: '0', md: '38px', lg: '58px' }}
            width={{ base: '100%', md: '363px' }}
            id="about-thevaram-section"
          >
            <SingleImage
              sectionContent={sectionContent[0]}
              my={0}
              w={{ base: '100%', md: '245px', lg: '363px' }}
              mx={0}
              minW={{ base: '100%', md: '245px', lg: '363px' }}
            />
          </Box>
          <StructuredTextParser
            maxW="685px"
            str={render(sectionContent[1].body)}
            // className="Thevaram"
            mt={{ base: '0', md: '31px', lg: '50px' }}
            lineHeight="150%"
            lang={lang}
          />
          <style>
            {`
            #about-thevaram-section .structured-content-dark{
                padding:0;
            }
              #about-thevaram-section h2 {
                color:#8D4D2D;
                font-style: normal;
font-weight: 350;
font-size: 48px;
line-height: 120%;
font-family:FedraSerifAStdBook;
              }
              #about-thevaram-section p{
                font-weight: 400;
font-size: 16px;
line-height: 150%;
font-family:FedraSansStd-book;
              }
              `}
          </style>
        </Flex>
      </Box>
    </Box>
  );
};
export default AboutThevaramSection;
