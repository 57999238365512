/* Built In Imports */
import dynamic from 'next/dynamic';

/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@msr//Utility/StructuredTextParser';
import SectionTitle from '@msr/components/Headings/MsrSectionTitle';

/* Services */

/**
 *
 * @param root0
 * @param root0.section
 * @param root0.lang
 * @returns
 */
export const ProgramLocationDetails = ({ section, lang }) => {
  const GoogleMap = dynamic(() => import('@components/Embeds/GoogleMap'));

  return (
    <Box
      color="#fff"
      maxW="1140px"
      width="full"
      display="flex"
      mx="auto"
      justifyContent="space-between"
      flexDir="column"
      pb={{ base: '20px', md: '50px' }}
    >
      <SectionTitle titleObj={section?.sectionContent[0]} />

      <Box
        p={{ base: '16px', md: '30px' }}
        justifyContent="center"
        gridGap={{ base: '15px', md: '40px' }}
        display="flex"
        flexDir={{ base: 'column', md: 'row' }}
      >
        <Box w="80%">
          <StructuredTextParser
            str={render(section.sectionContent[1]?.body)}
            mt="0"
            theme="light"
            fontWeight="bold"
            fontSize="18px"
            lang={lang}
          />
          <StructuredTextParser
            str={render(section.sectionContent[2]?.body)}
            mt="-20px"
            theme="light"
            fontWeight="bold"
            fontSize="18px"
            lang={lang}
          />
          
          <Box mt="30px">
            <StructuredTextParser
              str={render(section.sectionContent[3]?.body)}
              fontSize={{ base: '26px', md: '40px' }}
              theme="light"
              color="#FFA735"
              lang={lang}
            />
            <StructuredTextParser
              str={render(section.sectionContent[4]?.body)}
              mt="0"
              fontSize={{ base: '26px', md: '40px' }}
              theme="light"
              tAlign="left"
              lang={lang}
            />
          </Box>
        </Box>
        <Box w="full" m="unset">
          <GoogleMap sectionContent={section.sectionContent[5]} />
        </Box>
      </Box>
    </Box>
  );
};
