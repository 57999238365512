/* Built In Imports */
import { Box } from '@chakra-ui/react';
/* External Imports */

/* Internal Imports */
import MediaEmbed from '@components/Embeds/MediaEmbed';
import MsrSectionTitle from '@msr/components/Headings/MsrSectionTitle';

/* Services */

/**
 * Renders the ArtistsLive Section component
 * @param {Object} sectionContent - Data for section
 * @param {string} region - Region for the Section
 * @param {string} lang - Language for the Section
 * @returns {ReactElement} - ArtistsLive Section Component
 */
export const CelebrationsGlimpses = ({ section, lang }) => {
  const embedObj = {
    embedUrl: section?.sectionContent[1].embedUrl,
    source: 'youtube',
  };
  return (
    <Box pb={{ base: '40px', lg: '140px' }} bgColor="#03062c">
      <Box
        bgImg={section?.styleConfig?.bgImage?.url}
        bgColor={section?.styleConfig?.bgColour || '#090D49'}
        bgRepeat="no-repeat"
        bgSize="cover"
        pt={{ base: '10px', lg: '30px' }}
        height={{ base: '330px', md: '650px' }}
        width="100%"
        mx="auto"
        px={{ base: '15px', lg: 0 }}
      >
        <Box
          position="relative"
          sx={{
            h2: {
              w: { base: 'inherit', md: '440px' },
              mb: { base: '19px', md: '40px' },
            },
          }}
        >
          <MsrSectionTitle titleObj={section?.sectionContent[0]} />
          <Box maxW="948px" mx="auto" zIndex="1" position="relative">
            <MediaEmbed sectionContent={embedObj} margin="0" width="100%" />
            <Box
              h="30px"
              bg="#01031A"
              filter="blur(12px)"
              mt="-10px"
              width="95%"
              mx="auto"
              zIndex="-1"
              position="relative"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};