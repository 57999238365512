/* Built in Imports */
/* External Imports */
import { Box, Flex } from '@chakra-ui/react';
/* Internal Imports */
/* Components */
import SocialMediaIcon from '@components/UI/SocialMediaIcon';
import SimpleButton from '@msr/components/Buttons/SimpleButton';
import SingleLineText from '@msr/components/UI/SingleLineText';
/* Styles */
/* Services */
/**
 * Renders SongDownloadSection Component
 *
 * @param {object} sectionContent - dato
 * @param {string} region - region of the page
 * @param {string} lang - Language of the page
 * @returns {ReactElement} SongDownloadSection Component
 */

const SongDownloadSection = ({ sectionContent, region, lang }) => {
  return (
    <Box
      height={{ base: 'auto', md: 'auto', lg: 144 }}
      display="grid"
      placeItems="center"
      mb="0"
      mt={{ base: '0', md: '41px', lg: '0' }}
      maxW="1440px"
      mx="auto"
      width="100%"
      pt={{ base: '0px', md: '0' }}
      pb={{ base: '20px', md: '0' }}
      fontFamily="FedraSansStd-book"
    >
      <Flex
        maxW="1106px"
        mx="auto"
        width="100%"
        flexWrap="wrap"
        justifyContent={{ base: 'center', md: 'center' }}
        alignItems="center"
        flexDir={{ base: 'column', md: 'column', lg: 'row' }}
        gridGap={{ base: 10, md: 10, lg: '0' }}
      >
        <Flex flexDir="column" alignItems="center" pos="relative">
          {/* <Button height="56px" mb="12px">
              Download full album
            </Button> */}
          {sectionContent[0].linkurl != '' && (<>
            <Box mr="20px">
              <SimpleButton
                style={sectionContent[0]}
                region={region}
                lang={lang}
                mb="0"
                mt="0"
                fontSize="16px"
                lineHeight="16px"
              />
            </Box>
          </>)}
          {/* <Text>Pay as you wish</Text> */}
          {sectionContent[1].text != '' && (<>
            <Box
              pos={{ base: 'static', md: 'absolute' }}
              bottom="-55px"
              left={0}
              right="0"
            >
              <SingleLineText
                mt="0"
                mb={0}
                mr="0"
                fontStyle="italic"
                fontWeight="400"
                fontSize="16px"
                lineHeight="154%"
                pt="12px"
                ml="0"
                sectionContent={sectionContent[1]}
              />
            </Box>
          </>)}
        </Flex>

        <Flex
          flexDir={{ base: 'row', md: 'column' }}
          flexWrap="wrap"
          height={{ md: '45px', base: 'auto' }}
          maxH={{ base: '100%', md: '45px' }}
          maxW="410px"
          justifyContent="center"
          alignItems="center"
          className="thevaram-social"
          width="100%"
          mt={{ base: '0', md: '30px', lg: '0' }}
        >
          <Flex
            height="100%"
            width={{ base: '100%', md: 'auto' }}
            maxW="300px"
            justifyContent="space-between"
            mb={{ base: 10, md: '0' }}
          >
            <style>
              {`
              .thevaram-social img {
                width:auto;
                height:43px;
                margin: 0 16px
                }
              `}
            </style>
            <SocialMediaIcon
              sectionContent={sectionContent[2]}
              height="43.12px"
              width="43.12px"
              class="SocialMediaImage"
            />
            <SocialMediaIcon
            sectionContent={sectionContent[3]}
            height="43.12px"
            width="43.12px"
            class="SocialMediaImage"
            />
            <SocialMediaIcon
              sectionContent={sectionContent[4]}
              height="43.12px"
              width="43.12px"
              class="SocialMediaImage"
            />
            <SocialMediaIcon
              sectionContent={sectionContent[5]}
              height="43.12px"
              width="43.12px"
              class="SocialMediaImage"
            />
            <SocialMediaIcon
              sectionContent={sectionContent[6]}
              height="43.12px"
              width="43.12px"
              class="SocialMediaImage"
            />
          </Flex>
          <Flex
            height="100%"
            width={{ base: '100%', md: 'auto' }}
            maxW="300px"
            justifyContent="space-between"
          // mb={{ base: 10, md: '0' }}
          >
            <SocialMediaIcon
              sectionContent={sectionContent[7]}
              height="43.12px"
              width="43.12px"
              class="SocialMediaImage"

            // src="https://images.sadhguru.org/mahashivratri/wp-content/uploads/2020/01/youtube-square-copy.png"
            />
            <SocialMediaIcon
              sectionContent={sectionContent[8]}
              height="43.12px"
              width="43.12px"
              class="SocialMediaImage"

            // src="https://images.sadhguru.org/mahashivratri/wp-content/uploads/2020/01/youtube-square-copy.png"
            />
            <SocialMediaIcon
              sectionContent={sectionContent[9]}
              height="43.12px"
              width="43.12px"
              class="SocialMediaImage"

            // src="https://images.sadhguru.org/mahashivratri/wp-content/uploads/2020/01/youtube-square-copy.png"
            />
          </Flex>
        </Flex>
        <SimpleButton
          style={sectionContent[8]}
          region={region}
          lang={lang}
          // bType={sectionContent[8]}
          mb="0"
          mt="0"
          padding="7px 12px"
          fontSize="16px"
          textTransform="uppercase"
          lineHeight="20px"
        />
      </Flex>
    </Box>
  );
};
export default SongDownloadSection;
