'use client';

/* Built In Imports */
import NextLink from 'next/link';
import { useContext } from 'react';

/* External Imports */
import {
  Box,
  Flex,
  Link,
  Text,
  useBreakpointValue,
  useMediaQuery
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import StyleConfig from '@msr/Common/StyleConfig';
import SimpleButton from '@msr/components/Buttons/SimpleButton';

/* Services */
import { RegionContext } from '@msr/store/region-context';

// Utils
import { canRenderContent } from '@msr/components/Utility/utils';

/**
 *
 * @param section.section
 * @param {object} section
 * @param section.region
 * @param section.sectionContent
 * @param section.lang
 * @returns
 */
const Info = ({ section, region, sectionContent, lang }) => {
  const flexAlign = (typeof window !== 'undefined') && useBreakpointValue(
    {
      base: 'center',
      md: 'flex-start',
    },
    {
      ssr: false,
    }
  );
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  const currRegion = useContext(RegionContext);
  const secRegion = section?.region?.toLowerCase();
  const renderContent = canRenderContent(
    currRegion,
    secRegion,
    section?.sectionContent
  );

  return (
    <>
      {isLargerThan768 ? (
        <StyleConfig
          id={section.sectionId}
          key={section.sectionId}
          style={section.styleConfig}
          pT="0"
          pB="0"
          mB="0"
          section={section}
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          backgroundPosition="center"
          region={region}
        >
          <RenderInfo
            sectionContent={sectionContent}
            flexAlign={flexAlign}
            isLargerThan768={isLargerThan768}
            lang={lang}
          />
        </StyleConfig>
      ) : (
        renderContent && (
          <Box bgColor="black" p="60px 20px">
            <RenderInfo
              sectionContent={sectionContent}
              flexAlign={flexAlign}
              isLargerThan768={isLargerThan768}
              lang={lang}
            />
          </Box>
        )
      )}
    </>
  );
};

const RenderInfo = ({ sectionContent, flexAlign, isLargerThan768, lang }) => {
  return (
    <Flex
      direction="column"
      maxW="100%"
      height={{
        base: 'auto',
        md: '500px',
        lg: '600px',
        xl: '700px',
        '3xl': '1100px',
      }}
      width="100%"
      mx="auto"
      background={{
        md: 'linear-gradient(89.88deg, rgba(0, 0, 0, 0.7) 0.92%, rgba(0, 0, 0, 0.5) 62.24%, rgba(0, 0, 0, 0) 92.81%)',
      }}
      justifyContent="center"
    >
      <Flex
        direction="column"
        color="white"
        alignItems={flexAlign}
        width={{ md: '50%', lg: '45%', xl: '45%' }}
        pl={{ md: '5%', lg: '8%', xl: '12%', '2xl': '20%' }}
      >
        <StructuredTextParser
          str={render(sectionContent[0]?.body)}
          className="msrInfoTopBanner"
          fontFamily="FedraSansStd-book"
        />

        {isLargerThan768 ? (
          <SimpleButton
            style={{
              ...sectionContent[1],
              buttonStyle: 'MSR-Orange-LeftIcon',
              imgPath: '/img/play-media-black.png',
            }}
            lang={lang}
            mt={{ base: '30px', lg: '40px' }}
            mb="0"
          />
        ) : (
          <NextLink href={sectionContent[1]?.linkUrl?.toString()} passHref legacyBehavior>
            <Link
              m={'30px 0 0 0'}
              display="block"
              fontWeight={'500'}
              fontFamily={'FedraSansStd-medium'}
              fontSize={'16px'}
              textAlign="center"
              color="yellow.100"
              textDecoration="none"
              width={'auto'}
            >
              <Flex>
                <LazyLoadImageComponent
                  src="/img/play-media-yellow.png"
                  width={'30px'}
                  height={'30px'}
                />
                <Text pl={'10px'} color="white.50">
                  {sectionContent[1].buttonText}
                </Text>
              </Flex>
            </Link>
          </NextLink>
        )}
      </Flex>
      <style jsx global>
        {`
          #msr-intro-section {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          }
        `}
      </style>
    </Flex>
  );
};

export default Info;
