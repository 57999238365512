/* Built In Imports */
/* External Imports */
import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Box,
    chakra
} from '@chakra-ui/react';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';

/* Internal Imports */
/* Components */
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import { getEmbedUrl } from '@components/Utility/utils';
import MidContainer from '@Layout/MidContainer';
import StructuredTextContent from '@msr/components/UI/StructuredTextContent';

/* Services */

/**
 * Renders the Faq component.
 *
 * @param sectionContent.sectionContent
 * @param {Array} sectionContent - Main FAQ data.
 * @param sectionContent.region
 * @param sectionContent.lang
 * @returns {ReactElement} AccordionSection component.
 */
const AccordionSection = ({ sectionContent, region, lang }) => {
  return (
    <MidContainer w={'768'}>
      <Accordion
        bgColor="#03062C"
        allowMultiple
        defaultIndex={[0]}
        m={{ base: '0 auto' }}
        width={{ base: '100%' }}
      >
        {sectionContent?.map((item, index) => {
          return (
            <AccordionItem
              borderBottom="1px solid #fff"
              borderTop="none"
              key={index}
            >
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    mb="2px"
                    px={0}
                    py={{ base: '20px', md: '45px' }}
                    fontFamily="'FedraSansStd-A-medium'"
                    fontSize={{ base: '18px', md: '26px' }}
                    color="#fff"
                    _hover={{ bgColor: '#03062C', color: '#ffffff' }}
                    _focus={{ shadow: 'transparent' }}
                    minHeight="55px"
                    lineHeight="1.5"
                  >
                    <chakra.div
                      flex="1"
                      textAlign="left"
                      color="#fff"
                    >
                      {item.title}
                    </chakra.div>
                    {/* <AccordionIcon /> */}
                    {isExpanded ? (
                      <Box
                        width={{ base: '20px', md: '24px' }}
                        p="4px"
                        border="2px solid #fff"
                        borderRadius="50%"
                        display="flex"
                      >
                        <MinusIcon fontSize={{ base: '10px', md: '12px' }} />
                      </Box>
                    ) : (
                      <Box
                        width={{ base: '20px', md: '24px' }}
                        p="4px"
                        border="2px solid #fff"
                        borderRadius="50%"
                        display="flex"
                      >
                        <AddIcon fontSize={{ base: '10px', md: '12px' }} />
                      </Box>
                    )}
                  </AccordionButton>
                  <AccordionPanel
                    pb={{ base: '30px', md: '60px' }}
                    px={0}
                    fontSize="1.1rem"
                    fontFamily="FedraSansStd-book"
                    lineHeight="1.61"
                  >
                    <Box
                      display="flex"
                      gridGap={{ base: '17.42px', md: '18px' }}
                      flexWrap="wrap"
                      w="full"
                    >
                      {item?.content[0]?.images?.map(img => {
                        return (
                          <LazyLoadImageComponent
                            src={img?.url}
                            alt={img?.alt}
                            title={img?.title}
                            width={{ base: '97.58px', md: '138px' }}
                            height={{ base: '79.83px', md: '113px' }}
                            loading="lazy"
                          />
                        );
                      })}
                    </Box>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          );
        })}
      </Accordion>
    </MidContainer>
  );
};

const RenderAccordionContent = ({ content }) => {
  return (
    <>
      {content?.content && (
        <Box
          w="100%"
          p="1%"
          textAlign="left"
          display="grid"
          fontFamily="FedraSerifAStdBook"
          fontSize="22px"
          color="#333"
          lineHeight="1.43"
        >
          {StructuredTextContent(content?.content)}
        </Box>
      )}
      {content?.video && (
        <Box position="relative" textAlign="center" m="30px auto">
          {content?.video?.provider === 'youtube' && (
            <LiteYouTubeEmbed id={getEmbedUrl(content?.video?.url)} cookie />
          )}
        </Box>
      )}
    </>
  );
};

export default AccordionSection;
