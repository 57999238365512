/* Built In Imports */
/* External Imports */
import { Box, Link } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

/* Internal Imports */
import MsrArtistVideoCardDetail from '@msr/components/Card/MsrArtistVideoCardDetail';
import MsrSectionTitle from '@msr/components/Headings/MsrSectionTitle';

/* Services */

/**
 * Renders the PastPerformances Section component
 * @param {Object} sectionContent - Data for section
 * @param {string} region - Region for the Section
 * @param {string} lang - Language for the Section
 * @returns {ReactElement} - PastPerformances Section Component
 */
const PastPerformances = ({ sectionContent, lang }) => {
  const videoCards = sectionContent?.slice(1);
  const [cards, setCards] = useState([]);
  const loadCount = 6;

  const loadMorePosts = async () => {
    let morePosts = videoCards.slice(cards.length, cards.length + loadCount);
    if (morePosts?.length > 0) {
      setCards(prev => [...prev, ...morePosts]);
    }
  };

  useEffect(() => {
    loadMorePosts();
  }, []);

  return (
    <Box color="white">
      <MsrSectionTitle
        titleObj={{
          titleDecoration: 'drums-above',
          titleText: sectionContent[0]?.titleText,
        }}
      />
      <Box
        maxW="1140"
        mx={{ base: '15px', xl: 'auto' }}
        flexWrap="wrap"
        display="flex"
        mb="40px"
        mt="40px"
        gridGap="15px"
        justifyContent="center"
      >
        {cards.map((secCon, i) => {
          if (secCon._modelApiKey === 'msr_artist_video_card_detail') {
            return (
              <Box
                display="flex"
                bg="blue.600"
                flexDirection="column"
                width={{ base: '100%', lg: '370px' }}
              >
                <MsrArtistVideoCardDetail sectionContent={secCon} />
              </Box>
            );
          }
        })}
      </Box>

      <Box w="100%" display="flex" mb="30px">
        {cards.length < videoCards.length && (
          <Link
            variant={'outline'}
            width="185px"
            p="16px 20px"
            fontWeight="500"
            borderRadius="5px"
            color="#FFA735"
            fontSize="16px"
            fontFamily="FedraSansStd-medium"
            textAlign="center"
            m="0 auto"
            mb="70px"
            background="#03062C"
            border="1px solid #FFA735"
            _hover={{
              textDecoration: 'none',
              color: '#DC8513',
              border: '1px solid #DC8513',
            }}
            onClick={loadMorePosts}
            title="Load More"
            rel="next"
          >
            Load More ({cards.length}/{videoCards.length})
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default PastPerformances;
