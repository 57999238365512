/* Built In Imports */
import NextLink from 'next/link';
import { useState } from 'react';

/* External Imports */
import {
    Box,
    Flex,
    Image,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Text,
    useDisclosure
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import { refineUrlDomain, refreshPage } from '@components/Utility/utils';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/* Styles */
import _ from 'lodash';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/**
 * Renders the ThevaramCardInfo3 Card component
 * @param {Object} sectionContent - Data for section
 * @param {string} lang - Language of the page
 * @returns {ReactElement} ThevaramCardInfo3 Card component
 */
const ThevaramCardInfo3 = ({ sectionContent, lang, csrData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sliderData, setSliderData] = useState([]);

  const NextArrow = props => {
    // consoleLog('testimonial', testimonialsObj);
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top="45%"
        zIndex="10"
        cursor="pointer"
        bgSize="20px"
        bgColor="transparent"
        border="0"
        borderRadius="100%"
        w="40px"
        h="40px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        // bgImage={`url(${config.staticPath}/assets/images/right-arrow.svg)`}
        right="-17px"
        onClick={onClick}
      >
        <svg
          width="24"
          height="40"
          viewBox="0 0 24 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.13584 1.82602C4.93518 0.646432 3.00561 0.663507 1.82602 1.86416C0.646431 3.06482 0.663507 4.99439 1.86416 6.17398L6.13584 1.82602ZM1.82635 33.8638C0.646576 35.0643 0.663355 36.9939 1.86383 38.1736C3.0643 39.3534 4.99387 39.3366 6.17365 38.1362L1.82635 33.8638ZM20 19.7193L22.1736 21.8555C22.7403 21.2789 23.0546 20.5009 23.0475 19.6926C23.0404 18.8842 22.7125 18.1118 22.1358 17.5453L20 19.7193ZM1.86416 6.17398L17.8642 21.8933L22.1358 17.5453L6.13584 1.82602L1.86416 6.17398ZM17.8264 17.5831L1.82635 33.8638L6.17365 38.1362L22.1736 21.8555L17.8264 17.5831Z"
            fill="#28231E"
          />
        </svg>
      </Box>
    );
  };

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top="45%"
        zIndex="17"
        cursor="pointer"
        bgSize="20px"
        bgColor="transparent"
        border="0"
        borderRadius="100%"
        w="40px"
        h="40px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        left="-4px"
        onClick={onClick}
      >
        <svg
          width="24"
          height="40"
          viewBox="0 0 24 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.8642 38.174C19.0648 39.3536 20.9944 39.3365 22.174 38.1358C23.3536 36.9352 23.3365 35.0056 22.1358 33.826L17.8642 38.174ZM22.1736 6.13617C23.3534 4.9357 23.3366 3.00613 22.1362 1.82635C20.9357 0.646576 19.0061 0.663353 17.8264 1.86383L22.1736 6.13617ZM4 20.2807L1.82635 18.1445C1.25975 18.7211 0.945407 19.4991 0.952498 20.3074C0.959589 21.1158 1.28753 21.8882 1.86416 22.4547L4 20.2807ZM22.1358 33.826L6.13584 18.1067L1.86416 22.4547L17.8642 38.174L22.1358 33.826ZM6.17365 22.4169L22.1736 6.13617L17.8264 1.86383L1.82635 18.1445L6.17365 22.4169Z"
            fill="#28231E"
          />
        </svg>
      </Box>
    );
  };

  const settings = {
    dots: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    customPaging: function (i) {
      return (
        <Text
          cursor="pointer"
          display="inline-block"
          w="10px"
          h="10px"
          m={{ base: '40px 8px 0 8px', md: '25px 8px 0 8px' }}
          bgColor="#C0955F"
          borderRadius="50%"
        ></Text>
      );
    },
  };

  const openSlider = id => {
    _.forEach(csrData, function (item) {
      if (item.sectionId === id.replace('#', '')) {
        setSliderData(item?.sectionContent[0]?.cards);
      }
    });
    onOpen();
  };

  return (
    <Box
      mx="auto"
      maxW={1170}
      padding={{ lg: '15px', md: '20px', base: '15px' }}
      userSelect="none"
    >
      <Flex
        minH={{ base: 'auto', md: '600px' }}
        overflowX="auto"
        overflowY="hidden"
        gridGap={{ lg: '31.04px', md: '20px', base: '31.04px' }}
        flexDir={{ lg: 'row', md: 'row', base: 'column' }}
        maxW={{
          base: 'auto',
          sm: 'auto',
          md: '770px',
          lg: '850px',
          xl: '1170px',
        }}
      >
        {sectionContent?.cards?.map((secCon, index) => {
          return (
            <Box
              id="card3"
              key={nanoid()}
              display="flex"
              flexDirection="column"
              // mx="auto"
              maxW={{
                base: '100%',
                md: '358.96px',
                // lg: '370px',
                lg: '358.96px',
              }}
              minW={{
                base: '280px',
                md: '358.96px',
                // lg: '370px',
                lg: '358.96px',
              }}
              position="relative"
              mb={'20px'}
            >
              <NextLink
                href={refineUrlDomain(secCon.buttonLink, '', lang)}
                passHref
                legacyBehavior>
                <Link
                  position="relative"
                  zIndex="1"
                  cursor={secCon.buttonLink ? 'cursor' : 'auto'}
                  onClick={e => refreshPage(e?.target?.href, e)}
                >
                  <LazyLoadImageComponent
                    src={secCon.thumbnail?.url}
                    alt={secCon.thumbnail?.alt}
                    title={secCon.thumbnail?.title}
                    objectFit="cover"
                    w="100%"
                    minH="210px"
                    // maxH={'226.62px'}
                    width="100%"
                    height={{ base: '320px', md: '358.96px' }}
                    border="5px solid #38281D"
                    userSelect="none"
                  />
                </Link>
              </NextLink>

              <Box>
                <Box
                  lineHeight={1.1}
                  fontFamily="FedraSansStd-A-medium"
                  fontSize="26px"
                  mt="35px"
                  color="#000000"
                >
                  {secCon.title}
                </Box>
                <Box
                  fontFamily="FedraSansStd-book"
                  fontSize="16px"
                  color={'gray.200'}
                  pt={15}
                  className="ThevaramCardInfo3"
                >
                  <StructuredTextParser
                    str={render(secCon.description)}
                    region={''}
                    lang={lang}
                    mt="0"
                  />
                  {/* {StructuredTextContent(secCon.description)} */}
                </Box>
                {secCon.buttonLink && (
                  <Box>
                    <Link
                      bgColor="transparent"
                      color="#000000"
                      onClick={e => openSlider(secCon?.buttonLink)}
                      borderRadius="5px"
                      display="inline-block"
                      my={19}
                      textDecor="underline"
                      fontFamily="FedraSansStd-medium"
                      fontSize="16px"
                      _hover={{
                        color: '#000000',
                        //   bgColor: '#cf4520',
                      }}
                      _focus={{
                        color: '#000000',
                        //   bgColor: '#cf4520',
                      }}
                      _active={{
                        color: '#000000',
                        //   bgColor: '#cf4520',
                      }}
                    >
                      {secCon.buttonText}
                    </Link>
                  </Box>
                )}
              </Box>

              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent background="transparent">
                  <ModalCloseButton />
                  <ModalBody>
                    <Box w="90%" m="0 auto">
                      <Slider
                        {...settings}
                        m="0"
                        mt={{ base: '40px', md: '48px', lg: '37px' }}
                      >
                        {sliderData?.map((card, index) => {
                          return (
                            <Box
                              overflow="hidden"
                              // p="0 5%"
                              key={nanoid()}
                              textAlign="center"
                              fontFamily="FedraSansStd-book"
                            >
                              <Image
                                src={card?.thumbnail?.url}
                                verticalAlign="middle"
                                minW={{ base: '318px', md: '370px' }}
                                pb="1vw"
                              />
                            </Box>
                          );
                        })}
                      </Slider>
                    </Box>
                  </ModalBody>
                </ModalContent>
              </Modal>

              <style global jsx>
                {`
                  .ThevaramCardInfo3 .structured-content-dark {
                    padding: 0;
                  }
                `}
              </style>
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
};

export default ThevaramCardInfo3;
