import { Box, Link } from '@chakra-ui/react';
import NextLink from 'next/link';
import CardGrid from '@msr/components/Card/CardGrid';
import MsrSectionTitle from '@msr/components/Headings/MsrSectionTitle';
import React from 'react';

const Attend = ({ sectionContent, lang }) => {
  return (
    <Box
      bgColor={'blue.400'}
      p={{ base: '60px 20px 20px 20px', lg: '60px 20px' }}
    >
      <MsrSectionTitle pt="0" mt="0" titleObj={sectionContent[0]} />
      <CardGrid
        sectionContent={sectionContent[1]}
        title={{ fontSize: '26px', color: 'white.50' }}
        sessions={{ fontSize: '18px', color: 'yellow.100' }}
        desc={{ color: 'blue.200', pt: { base: '10px', md: '25px' } }}
        pageType="attend"
        lang={lang}
      />
      {
        sectionContent[2]?._modelApiKey === 'simple_button' && (
            <Box display="flex" mt={{ base: '10px', lg: '20px' }} justifyContent="center">
              <Box d="flex" justifyContent="center">
                <NextLink href={sectionContent[2]?.linkUrl} passHref legacyBehavior>
                  <Link
                      className="orange-button"
                      m="auto"
                      display="block"
                      fontWeight={'500'}
                      fontFamily={'FedraSansStd-medium'}
                      fontSize={'16px'}
                      bgColor="yellow.100"
                      h={'auto'}
                      minH="50px"
                      textAlign="center"
                      padding={'18px 20px'}
                      borderRadius="5px"
                      color="black"
                      textDecoration="none"
                      width={'auto'}
                      _hover={{
                        bg: 'fulvous',
                      }}
                      target={'_blank'}
                    >
                      {sectionContent[2]?.buttonText}
                  </Link>
                </NextLink>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Attend;
