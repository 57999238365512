/* Built In Imports */
/* External Imports */
import { Flex } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import MidContainer from '@components/Layout/MidContainer';
import SingleLineText from '@msr/components/UI/SingleLineText';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/* Services */

/**
 * Renders the ArtistsLive Section component
 * @param {Object} sectionContent - Data for section
 * @param {string} region - Region for the Section
 * @param {string} lang - Language for the Section
 * @returns {ReactElement} - ArtistsLive Section Component
 */
export const CelebrationsDesc = ({ sectionContent, lang }) => {
  return (
    <MidContainer w="1120px">
      <Flex
        w="100%"
        justifyContent="space-between"
        py={{ base: '40px', lg: '80px' }}
        px={{ base: '15px', xl: 0 }}
        direction={{ base: 'column', lg: 'row' }}
      >
        <Flex maxW={{ lg: '380px', xl: '347px' }} width="full">
          <Flex direction="column" justifyContent="start">
            <SingleLineText
              sectionContent={sectionContent[0]}
              fontSize={{ base: '30px', lg: lang == 'ta' ? '36px' : '48px' }}
              mt="0"
              ml={{ base: '0', md: '10px' }}
              pt="0"
              color="yellow.100"
              lineHeight="1.2"
            />
            <SingleLineText
              sectionContent={sectionContent[1]}
              mt="0"
              ml={{ base: '0', md: '10px' }}
              pt="0"
              placeContent="flex-start"
              fontSize={{ base: '50px', lg: '120px' }}
              fontFamily="FedraSansStd-A-medium"
              color="yellow.100"
              lineHeight="1.0"
              fontWeight="500"
            />
          </Flex>
        </Flex>
        <Flex maxW="700px" width="full" className="celebrationBox" mt="-13px">
          <StructuredTextParser
            str={render(sectionContent[2]?.body)}
            region={''}
            lang={lang}
            tAlign={sectionContent.style}
            theme="light"
            textAlign="left"
          />
        </Flex>
      </Flex>
      <style>
        {`
          .celebrationBox .structured-content-light {
            max-width:700px;
            width:100%
          }
        `}
      </style>
    </MidContainer>
  );
};

export default CelebrationsDesc;
