/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import SimpleButton from '@msr/components/Buttons/SimpleButton';

/**
 *
 * @param {Object} sectionContent
 * @returns
 */
const CelebrationsYaksha = ({ sectionContent, lang }) => {
  return (
    <>
      <Box bgColor="black" p="60px 20px">
        <Box
          // height={{ base: '1200px', lg: '1100px' }}
          background={{
            base: `url('${sectionContent[4]?.mobileImage?.url}') no-repeat center 40%`,
            md: `url('${sectionContent[4]?.image?.url}') no-repeat center 1%`,
            lg: `url('${sectionContent[4]?.image?.url}') no-repeat center 1%`,
            xl: `url('${sectionContent[4]?.image?.url}') no-repeat center 300%`,
          }}
          backgroundSize={{
            base: 'unset',
            md: '100%',
            lg: '75%',
            xl: 'unset',
          }}
        >
          <Box color="yellow.100" textAlign="center" m="0 auto">
            <Box
              as="h2"
              fontSize={{ base: '36px', lg: '48px' }}
              fontWeight="bold"
              textAlign="center"
              lineHeight="110%"
              fontFamily="FedraSerifAStdBook"
            >
              {sectionContent[0]?.text}
            </Box>
            <Box
              fontFamily="FedraSerifAStdBook"
              fontSize={{ base: '22px', lg: '26px' }}
              fontWeight="bold"
              lineHeight="110%"
            >
              {sectionContent[1]?.text}
            </Box>
            <Box textAlign="center">
              <StructuredTextParser
                str={render(sectionContent[2]?.body)}
                textAlign="center-align"
                color="white.50"
                fontFamily="FedraSansStd-book"
                fontSize={{ base: '16px', md: '18px', lg: '16px' }}
                maxW="500px"
                width={{ base: '280px', md: '450px', lg: '100%' }}
                mx="auto"
                lang={lang}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection={{ base: 'column-reverse', md: 'column' }}
            m={{
              base: '26 0px auto 0 auto',
              md: '430px auto 0 auto',
              lg: '430px auto 0 auto',
              xl: '600px auto 0 auto',
            }}
            justifyContent="center"
          >
            <SimpleButton
              style={sectionContent[5]}
              mt={{ base: '20px', md: '40px' }}
              mb={{ base: 0, lg: '20px' }}
              lang={lang}
            />
            <Box textAlign="center">
              <StructuredTextParser
                str={render(sectionContent[6]?.body)}
                textAlign="center-align"
                color="white"
                fontFamily="FedraSansStd-book"
                fontSize={{ base: '18px', md: '22px', lg: '26px' }}
                fontWeight="bold"
                maxW={{ base: '470px', md: lang == 'it' ? '990px' : '470px' }}
                width={{ base: '300px', md: '350px', lg: '100%' }}
                mx="auto"
                lang={lang}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CelebrationsYaksha;
