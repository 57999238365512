/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';

/* Services */

/**
 * Renders the YogaCommonUI component.
 *
 * @param {Array} sectionContent - Content from api.
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @returns {ReactElement} SingleLineText Component.
 */

const SingleLineText = ({
  sectionContent,
  region,
  lang,
  fontStyle,
  lineHeight,
  color,
  fontSize,
  pt,
  mt,
  mr,
  mb,
  ml,
  textAlign,
  placeContent,
  as,
  ...props
}) => {
  return (
    <MidContainer w={props.w}>
      <Box
        maxW="100%"
        mt={mt || '10px'}
        mr={mr || '10px'}
        mb={mb || '10px'}
        ml={ml || '10px'}
        pt={pt || '30px'}
        pr="0"
        display="grid"
        placeContent={placeContent || 'center'}
      >
        <Box
          width="100%"
          fontSize={fontSize || { base: '16px', lg: '18px' }}
          color={color || '#28231e'}
          lineHeight={lineHeight}
          fontStyle={fontStyle}
          {...props}
        >
          <Box as={as || 'div'} textAlign={textAlign || 'left'}>
            {sectionContent?.text}
          </Box>
        </Box>
      </Box>
    </MidContainer>
  );
};

export default SingleLineText;
