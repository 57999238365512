/* External Imports */
import { Box, Heading } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
import StructuredTextParser from '@msr//Utility/StructuredTextParser';
import StyleConfig from '@msr/Common/StyleConfig';
/**
 *
 * @returns
 */
export const AtHomeTitleSection = ({ section, sectionContent, lang }) => {
  return (
    <StyleConfig
      id={section.sectionId}
      key={section.sectionId}
      style={section.styleConfig}
      section={section}
      mB="20px"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition="center"
    >
      <Box className="page-wrap" py={{ base: '40px', md: '60px' }}>
        <Heading
          as="h2"
          fontSize={{ base: '26px', md: '48px' }}
          color="yellow.100"
          fontWeight="500"
          textAlign="center"
          mb="16px"
        >
          {sectionContent[0]?.titleText}
        </Heading>
        <StructuredTextParser
          str={render(sectionContent[1]?.body)}
          theme="light"
          fontSize="18px"
          color="#C1C3DD"
          textAlign="center"
          mx="auto"
          lang={lang}
        />
      </Box>
      <style jsx global>{`
        #athome-content-section {
          padding: 0;
        }
        #athome-content-section .embedBox {
          max-width: 755px;
        }
        #athome-content-section .embedBox .structured-content-light {
          max-width: 755px;
        }
        #athome-content-section .embedBox .structured-content-light p {
          margin-top: 0;
          margin-bottom: 0;
        }
        #midnight-sadhana-section {
          margin-top: 40px;
          margin-bottom: 40px;
        }
        @media (max-width: 599px) {
          #midnight-sadhana-section,
          #athome-content-section {
            padding: 0 15px;
          }
        }
        @media (min-width: 600px) and (max-width: 959px) {
          #midnight-sadhana-section,
          #athome-content-section {
            padding: 0 15px;
          }
        }
      `}</style>
    </StyleConfig>
  );
};
