/* External Imports */
import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import NextLink from 'next/link';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import MsrSectionTitle from '@msr/components/Headings/MsrSectionTitle';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/**
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent
 * @param sectionContent.lang
 * @returns
 */
const ShivaChants = ({ sectionContent, lang }) => {
  const sectionTitleConfig = sectionContent[0];
  sectionTitleConfig.color = 'white';
  const shivaChantList = sectionContent[1];
  const NextArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top="140px"
        zIndex="10"
        cursor="pointer"
        bgSize="20px"
        w="46px"
        h="46px"
        boxShadow="0 0 7px 0 rgb(0 0 0 / 13%)"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        bgImage={`url('${config.imgPath}/d/46272/1664373600-vector-2.png')`}
        right={{ base: '-10px', md: '-40px' }}
        onClick={onClick}
        display={{ base: 'none', md: 'block' }}
      ></Box>
    );
  };
  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top="140px"
        zIndex="10"
        cursor="pointer"
        bgSize="20px"
        boxShadow="0 0 7px 0 rgb(0 0 0 / 13%)"
        w="46px"
        h="46px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        bgImage={`url('${config.imgPath}/d/46272/1664373605-vector-3.png')`}
        left={{ base: '-10px', md: '-40px' }}
        onClick={onClick}
        display={{ base: 'none', md: 'block' }}
      />
    );
  };

  const settings = {
    dots: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 11000,
    lazyLoad: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    centerMode: false,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
    customPaging: function (i) {
      return (
        <Text
          cursor="pointer"
          display="inline-block"
          w="12px"
          h="12px"
          m="0px 8px"
          bgColor="#c4c4c4"
          borderRadius="50%"
        ></Text>
      );
    },
  };

  return <>
    <Box
      id="chants-container"
      background="blue.400"
      border="none"
      p="60px 20px 0"
      sx={{
        h2: {
          mb: '0',
        },
      }}
    >
      <MsrSectionTitle
        mt="0"
        pt="0"
        titleObj={sectionTitleConfig}
        w={{ base: '250px', md: '400px' }}
      />
      <Box
        px={{ base: '0', md: '50px' }}
        py="20px"
        maxW={{ base: '100%', lg: '1162px' }}
        m="0 auto"
        id="chants-slider"
      >
        <Slider {...settings} m="0">
          {shivaChantList &&
            shivaChantList?.downloadCards?.map((cardDetail, index) => {
              return (
                <Box overflow="hidden" display="flex" flexWrap="wrap" key={index}>
                  <Flex
                    width={{ base: '95%' }}
                    margin={{
                      base: '0 auto 10px auto',
                      md: '0 auto',
                    }}
                    position="relative"
                    direction="column"
                    alignItems="center"
                  >
                    <Image
                      src={cardDetail?.thumbnailImage?.url}
                      width="300px"
                      objectPosition="top"
                      height="300px"
                      alt={
                        cardDetail?.thumbnailImage?.alt || cardDetail.title
                      }
                    />
                    <Box
                      bottom="0"
                      width="100%"
                      backgroundSize="cover"
                      m="20px auto 10px auto"
                      padding="20px 20px 20px 20px"
                      zIndex="10"
                      right="0"
                      left="0"
                    >
                      <Box
                        fontFamily="FedraSerifAStdBook"
                        fontSize="26px"
                        lineHeight="1.25"
                        color="white.50"
                        textAlign="center"
                        fontWeight="600"
                        className="truncate-text line-1"
                      >
                        {cardDetail?.title}
                      </Box>
                      <Box
                        fontSize="16px"
                        lineHeight="1.5"
                        textAlign="center"
                        className="truncate-text shiva-chants-desc"
                        sx={{
                          div: {
                            mt: '0',
                          },
                        }}
                      >
                        <StructuredTextParser
                          str={render(cardDetail?.description)}
                          textAlign="center"
                          fontFamily="FedraSansStd-book"
                          theme="light"
                        />
                      </Box>
                      <Flex
                        justifyContent="center"
                        mt={{ base: '40px', md: 'inherit' }}
                      >
                        <NextLink href={cardDetail?.downloadLink} passHref legacyBehavior>
                          <Link
                            className="orange-button"
                            m="auto"
                            display="block"
                            fontWeight={'500'}
                            fontFamily={'FedraSansStd-medium'}
                            fontSize={'16px'}
                            bgColor="yellow.100"
                            h={'auto'}
                            minH="50px"
                            textAlign="center"
                            padding={'18px 20px'}
                            borderRadius="5px"
                            color="black"
                            textDecoration="none"
                            width={'auto'}
                            _hover={{
                              bg: 'fulvous',
                            }}
                            target={'_blank'}
                          >
                            <Flex justifyContent="center" alignItems="center">
                              <Text pr={'10px'}>Download</Text>
                              <LazyLoadImageComponent
                                src={'/img/download.png'}
                                width={'20px'}
                                pt={'0'}
                              />
                            </Flex>
                          </Link>
                        </NextLink>
                      </Flex>
                    </Box>
                  </Flex>
                </Box>
              );
            })}
        </Slider>
      </Box>
      
      <Box
        className="white-link"
        fontSize="18px"
        pt={{ base: '15px', md: '35px' }}
      >
        <StructuredTextParser
          str={render(sectionContent[2]?.body)}
          fontFamily="FedraSansStd-book"
          textAlign="center"
          lang={lang}
        />
      </Box>
    </Box>
    <style jsx global>
      {`
        .slick-dots {
          margin-bottom: 0px;
        }
        .slick-dots li.slick-active p {
          background-color: #f5ac3e;
        }
        .white-link p {
          color: white;
          font-size: 18px !important;
          margin-bottom: 0px;
        }
        .white-link a {
          text-decoration: underline;
          color: white !important;
        }
        .white-link strong {
          text-decoration: none;
          color: white !important;
        }
        .shiva-chants-desc p {
          color: #8f92c1;
          margin: 10px auto 0 auto;
        }
        @media (max-width: 768px) {
          #chants-slider .slick-list .slick-active {
            width: 300px !important;
          }
        }
        @media (max-width: 320px) {
          #chants-container {
            padding: 60px 0;
          }
        }
      `}
    </style>
  </>;
};

export default ShivaChants;
