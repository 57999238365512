/* Built In Imports */
import { useRef, useState } from 'react';

/* External Imports */
import {
    Box,
    Button,
    Modal,
    ModalContent,
    ModalOverlay,
    useDisclosure
} from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import SectionUI from '@msr/Common/SectionUI';
import SubscriptionModal from '@msr/components/Modal/SubscriptionModal';

/**
 * Renders the YakshaPerfArtist Section component
 * @param {Object} sectionContent - Data for section
 * @param {string} lang - Language for the Section
 * @returns {ReactElement} - YakshaPerfArtist Section Component
 */
const YakshaPerfArtist = ({ sectionContent, lang }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = useRef(null);
  const [messageTemp, setMessageTemp] = useState('');

  return (
    <>
      {sectionContent.map((secCon, i) => {
        if (
          secCon._modelApiKey === 'simple_button' ||
          secCon._modelApiKey === 'newsletter_subscription_form'
        ) {
          if (secCon._modelApiKey === 'simple_button') {
            let newsLetter = {};
            newsLetter[sectionContent[i + 1]?.newsletterIdentifier] = sectionContent[i + 1]?.newsletterIdentifier;
            // consoleLog('newsLetter', newsLetter);
            return (
              <>
                <Box d="flex" justifyContent="center">
                  {/* <SimpleButton
                style={secCon}
                region={''}
                    lang={lang}
                    clickHandler={onOpen}
              /> */}
                  <Button
                    type="submit"
                    className="orange-button"
                    mb={'60px'}
                    mt="0px"
                    display="block"
                    fontWeight={'500'}
                    fontFamily={'FedraSansStd-medium'}
                    fontSize={'16px'}
                    bgColor="yellow.100"
                    h={'auto'}
                    minH="50px"
                    border="none"
                    textAlign="center"
                    padding={'18px 20px'}
                    borderRadius="5px"
                    color="black"
                    textDecoration="none"
                    width={'auto'}
                    _hover={{
                      bg: 'fulvous',
                    }}
                    onClick={onOpen}
                  >
                    {secCon?.buttonText}
                  </Button>
                  <Modal
                    finalFocusRef={finalRef}
                    isOpen={isOpen}
                    onClose={onClose}
                    isCentered
                    motionPreset="slideInBottom"
                    size={!messageTemp ? 'xl' : '4xl'}
                    blockScrollOnMount={false}
                    closeOnOverlayClick={false}
                  // scrollBehavior="inside"
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <SubscriptionModal
                        sectionContent={sectionContent[i + 1]}
                        messageT={messageTemp}
                        newsletters={newsLetter}
                        dataLayer={{
                          event: 'msr_vp_pushed',
                          virtual_path: '/msr_live_stream_vp_success'
                        }}
                      />
                    </ModalContent>
                  </Modal>
                </Box>
              </>
            );
          }
        } else {
          return (
            <SectionUI
              key={i}
              sectionContent={secCon}
              region={''}
              language={lang}
            />
          );
        }
      })}
    </>
  );
};

export default YakshaPerfArtist;

