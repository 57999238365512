/* Built in Imports */
/* External Imports */
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import SimpleButton from '@msr/components/Buttons/SimpleButton';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/**
 * Renders StoryOfNayanmars Component
 * @param {Object} sectionContent - dato
 * @param {string} lang - Language of the page
 * @returns {ReactElement}  Component StoryOfNayanmars
 */
export function StoryOfNayanmars({ sectionContent, lang }) {
  return (
    <Box
      display="block"
      height="423px"
      // background="#FFA735"
      width="100%"
      padding="15px"
      py={{ base: '60px', md: '59px' }}
      pb="29px"
      textAlign={{ base: 'left', md: 'center' }}
    >
      <Grid
        placeItems={{ base: 'normal', md: 'center' }}
        alignItems="center"
        width="100%"
        height="100%"
      >
        <GridItem width="100%" my="auto">
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            maxW="586px"
            mx="auto"
            my="auto"
            className="storyOfNaynanmars"
          >
            <style>
              {`
                .storyOfNaynanmars h2{
                  font-style: normal;
font-weight: 350;
font-size: 48px;
line-height: 120%;
font-family:FedraSerifAStdBook;
color: #FFF7E9;
text-align:center;
                }
                .storyOfNaynanmars p{
                  font-style: normal;
font-weight: 400;
font-size: 16px;
color: #FFF7E9;
line-height: 150%;
text-align:center;
                }
                @media only screen and (max-width: 600px) {
                  .storyOfNaynanmars h2 {
    text-align:left;
    font-weight: 500;
font-size: 40px;
line-height: 120%;
  }
  .storyOfNaynanmars p {
    text-align:left;
  }
}
                `}
            </style>
            <StructuredTextParser
              str={render(sectionContent[0].body)}
              StructuredTextParser
              // str={render(faq.answer)}
              region={''}
              lang={lang}
            />
            <SimpleButton
              width="209px"
              textAlign="center"
              style={sectionContent[1]}
              lang={lang}
            />
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default StoryOfNayanmars;
