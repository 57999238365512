/* Built In Imports */
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useState } from 'react';

/* External Imports */
import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    ModalBody,
    ModalCloseButton,
    ModalHeader,
    Select
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import parser from 'html-react-parser';
import { nanoid } from 'nanoid';
import * as Yup from 'yup';

/* Internal Imports */
/* Components */
import InputUI from '@components/Elements/InputUI';
import config from '@config';
const Recaptcha = dynamic(() => import('@components/UI/Subscribe/Recaptcha'));

/* Services */
import CountriesList from '@constants/CountriesList';
import { addSubscription } from 'services/msrService';

/**
 * Renders the SubscriptionModal component.
 *
 * @param sectionContent.sectionContent
 * @param {Array} sectionContent - Content from api.
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @param sectionContent.messageT
 * @param sectionContent.dataLayer
 * @param sectionContent.newsletters
 * @param sectionContent.date
 * @returns {ReactElement} SubscriptionModal Component.
 */
const SubscriptionModal = ({
  sectionContent,
  messageT,
  dataLayer,
  newsletters,
  date,
}) => {
  const router = useRouter();
  const [messageTemp, setMessageTemp] = useState(messageT);
  const [loading, setLoading] = useState(false);

  /**
   *
   * @param num
   */
  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  /**
   *
   * @param date
   */
  function formatDate(date) {
    date = new Date(new Date(date).toLocaleString('en-US', {timeZone: 'Asia/Kolkata'}));
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getUTCFullYear(),
    ].join('/');
  }

  const ValidationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Name is invalid')
      .matches(/^([a-zA-Z ])+$/u, {
        message: 'Name is invalid',
      })
      .max(100, 'Name is invalid')
      .required('Name is required'),
    email: Yup.string()
      .required('Email is required')
      .matches(/^[a-zA-Z]+[a-zA-Z0-9._]+@[a-zA-Z]+(\.[a-zA-Z]{2,15})+$/, {
        message: 'Email is invalid',
      }),
    country: Yup.string().required('Country is required'),
    recaptcha: Yup.string().required('Cannot go past captcha'),
  });

  const onSubmit = async (values, actions) => {
    setLoading(true);
    let formValues = values;
    formValues['opt-in'] = values['opt-in'] ? 'yes' : 'no';

    actions.setSubmitting(true);
    let formResponse = await addSubscription(formValues);
    // consoleLog('typeof formResponse === string', typeof formResponse === 'string', formResponse, formResponse[0]?.status_code === 103);

    if (typeof formResponse === 'string') {
      setMessageTemp(
        `<div >Sorry something went wrong. We are not able to process your request right now.</div>`
      );
      // onOpen();
      // setLoading(false);
    } else if (
      formResponse[0]?.status_code === 101 ||
      formResponse[0]?.status_code === 102 ||
      formResponse[0]?.status_code === 103 ||
      formResponse[0]?.status_code === 104 ||
      formResponse[0]?.status_code === 105 ||
      formResponse[0]?.status_code === 106
    ) {
      setMessageTemp(formResponse[0]?.message_template);
    } else {
      setMessageTemp(
        `<div >Sorry something went wrong. We are not able to process your request right now.</div>`
      );
    }
    // consoleLog('dataLayer started');
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataLayer);
    setLoading(false);
  };

  return (
    <>
      <ModalHeader
        textAlign="center"
        color="#424786"
        m={!messageTemp ? '20px auto 10px' : '0'}
        fontSize="26px"
      >
        {!messageTemp ? sectionContent?.content?.json?.title : ' '}
      </ModalHeader>

      <ModalCloseButton color="#9F9F9F" mt="1" />
      <ModalBody p="0">
        {!messageTemp && (
          <Flex flexDir="column" w={{ base: '95%', md: '384px' }} mx="auto">
            <Formik
              initialValues={{
                name: '',
                email: '',
                country: '',
                'opt-in': false,
                newsletters: newsletters,
                source: `${config.BASE_PATH}${router.asPath}`,
                date: date ? formatDate(date) : '',
                recaptcha: '',
              }}
              validationSchema={ValidationSchema}
              onSubmit={onSubmit}
            >
              {props => (
                <Form
                  onSubmit={props.handleSubmit}
                  style={{
                    display: 'grid',
                    rowGap: '25px',
                    boxSizing: 'border-box',
                  }}
                >
                  <Field name="name">
                    {({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={form.errors.name && form.touched.name}
                        display="flex"
                        flexDir="column"
                        // m={{ md: '0 0 0 15px', sm: '0', base: '0' }}
                        fontFamily="FedraSansStd-book"
                      >
                        <FormLabel
                          htmlFor="name"
                          // color="#676055"
                          fontWeight="500"
                        >
                          {sectionContent?.content?.json?.name}
                        </FormLabel>
                        <InputUI
                          // wd={{ md: '215px', sm: '460px', base: '300px' }}
                          // m={{ md: '0 0 0 15px', sm: '0', base: '0' }}
                          color="#000"
                          field={field}
                          id="lastName"
                          placeholder={sectionContent?.content?.json?.name}
                          onChange={props.handleChange}
                          _placeholder={{
                            color: '#C4C4C4',
                            fontSize: '16px',
                          }}
                        />
                        <FormErrorMessage justifyContent="left">
                          {form.errors.name}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="email">
                    {({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={form.errors.email && form.touched.email}
                      >
                        <FormLabel
                          htmlFor="email"
                          fontWeight="500"
                          fontFamily="FedraSansStd-book"
                        >
                          {sectionContent?.content?.json?.email}
                        </FormLabel>
                        <InputUI
                          color="#000"
                          border="0.3px solid #000000"
                          field={field}
                          id="email"
                          placeholder={sectionContent?.content?.json?.email}
                          onChange={props.handleChange}
                          _placeholder={{
                            color: '#C4C4C4',
                            fontSize: '16px',
                          }}
                        />
                        <FormErrorMessage justifyContent="left">
                          {form.errors.email}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="country">
                    {({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={form.errors.country && form.touched.country}
                        width="100%"
                        m={{ base: '0 0 10px 0' }}
                      >
                        <FormLabel
                          htmlFor="name"
                          fontFamily="FedraSansStd-book"
                          fontWeight="500"
                        >
                          {sectionContent?.content?.json?.country}
                        </FormLabel>
                        <Box
                          sx={{
                            select: {
                              paddingInlineStart: '0',
                            },
                          }}
                        >
                          <Select
                            key="country"
                            width="100%"
                            height="56px"
                            backgroundColor="#fff"
                            isRequired={true}
                            placeholder={sectionContent?.content?.json?.country}
                            fontFamily="FedraSansStd-book, sans-serif"
                            color="#605D5D"
                            borderRadius="0"
                            border="0.3px solid #aea493"
                            borderTop="none"
                            borderRight="none"
                            borderLeft="none"
                            paddingLeft="0"
                            outline="0"
                            _placeholder={{
                              color: '#605D5D',
                              fontSize: '16px',
                            }}
                            p="0"
                            {...field}
                          >
                            {CountriesList.map(country => {
                              return (
                                <option key={nanoid()} value={country.value}>
                                  {country.name}
                                </option>
                              );
                            })}
                          </Select>
                        </Box>
                        <FormErrorMessage>
                          {form.errors.country}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  {sectionContent?.showOptIn && (
                    <Field name="opt-in">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors['opt-in'] && form.touched['opt-in']
                          }
                          // isRequired
                        >
                          <Box
                            display={{ base: '-webkit-box', md: 'flex' }}
                            lineHeight="18px"
                          >
                            <Checkbox
                              colorScheme="orange"
                              onChange={props.handleChange}
                              checked={props.values['opt-in']}
                              {...field}
                            >
                              <Box
                                as="span"
                                fontFamily="FedraSansStd-book"
                                fontSize="12px"
                                color="#525252"
                              >
                                {sectionContent?.content?.json?.optinText}
                              </Box>
                            </Checkbox>
                          </Box>
                          <FormErrorMessage justifyContent="left">
                            {form.errors['opt-in']}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  )}
                  <Field name="recaptcha">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.recaptcha && form.touched.recaptcha
                        }
                        //   width={{ base: 'auto', xl: 'calc((100% - 40px)/3)' }}
                        m="0 auto"
                        textAlign="center"
                      >
                        {config.RECAPTCHA_KEY && (
                          <Recaptcha
                            props={props}
                            sitekey={config.RECAPTCHA_KEY}
                          />
                        )}
                        <FormErrorMessage justifyContent="left">
                          {form.errors.recaptcha}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Box display="grid" placeContent="center">
                    <Button
                      type="submit"
                      className="orange-button"
                      mb={'39px'}
                      display="block"
                      fontWeight={'500'}
                      fontFamily={'FedraSansStd-medium'}
                      fontSize={'16px'}
                      bgColor="yellow.100"
                      h={'auto'}
                      minH="50px"
                      border="none"
                      textAlign="center"
                      padding={'18px 20px'}
                      borderRadius="5px"
                      color="black"
                      textDecoration="none"
                      width={'auto'}
                      _hover={{
                        bg: 'fulvous',
                      }}
                      disabled={
                        !props.isValid || !props.dirty || loading ? true : false
                      }
                    >
                      {loading
                        ? 'Submitting..'
                        : sectionContent?.content?.json?.submitButton}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Flex>
        )}
        {messageTemp && (
          <Box
            maxW={messageTemp ? { base: '90%', md: '476px' } : 'auto'}
            m="0 auto 10px auto"
          >
            {parser(messageTemp || '')}
          </Box>
        )}
      </ModalBody>
    </>
  );
};

export default SubscriptionModal;
