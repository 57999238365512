/* Built In Imports */
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';
const MediaEmbed = dynamic(() => import('@msr/components/Embeds/MediaEmbed'));

/* Services */
import { fetchLiveStreamVideo } from 'services/msrService';

/**
 * @param sectionContent
 * @param lang
 * @returns
 */
const BackupLiveStreamVideo = ({ sectionContent, lang = 'en' }) => {
  const [videoData, setVideoData] = useState(null);
  const [vidResp, setVidResp] = useState(null);

  useEffect(() => {
    getLiveStreamVideo(lang);
  }, [lang]);

  useEffect(() => {
    if (vidResp?.id) {
      // console.log('vidResp', vidResp, videoData);
      if (
        vidResp.active === 'youtube' &&
        (!videoData ||
          videoData?.source !== 'youtube' ||
          vidResp.id != videoData?.vId)
      ) {
        const vidD = JSON.parse(
          JSON.stringify({
            vId: vidResp.id,
            eventClosed: vidResp.eventClosed,
            embedUrl: vidResp.url,
            source: 'youtube',
            style: 'default',
            image: null,
            _modelApiKey: 'media_embed',
          })
        );
        setVideoData(vidD);
      } else if (
        vidResp.active === 'fb' &&
        (!vidResp || vidResp.source !== 'fb' || vidResp.id !== videoData?.vId)
      ) {
        // console.log('222', vidResp);
        setVideoData({
          _modelApiKey: 'media_embed',
          embedUrl: vidResp.url,
          source: 'fb',
          style: 'default',
          vId: vidResp.id,
          eventClosed: vidResp.eventClosed,
        });
      }

      if (!vidResp?.eventClosed) {
        setTimeout(() => {
          getLiveStreamVideo(lang);
        }, 60000);
      } else {
        setVideoData(sectionContent);
      }
    } else {
      // console.log('333', videoData);
      setVideoData(sectionContent);
    }
  }, [vidResp]);

  const getLiveStreamVideo = async lang => {
    try {
      const response = await fetchLiveStreamVideo(lang);
      setVidResp(response?.data);
      console.log('response', response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {(videoData?.source === 'youtube' || videoData?.source === 'fb') && (
        <MidContainer w={'755px'}>
          <Box
            position="relative"
            display="block"
            width={''}
            pb={'56.25%'}
            overflow="hidden"
            height="0"
            m={'30px auto'}
            px={{ base: '15px', md: 0 }}
          >
            <Box
              as="iframe"
              width="100%"
              allowFullScreen
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
              src={`${videoData.embedUrl}`}
              frameborder="0"
              allow="clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></Box>
          </Box>
        </MidContainer>
      )}
    </>
  );
};

export default BackupLiveStreamVideo;
