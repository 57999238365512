/* Built In Imports */
/* External Imports */
import MidContainer from '@components/Layout/MidContainer';

/* Internal Imports */
import SectionUI from '@msr/Common/SectionUI';

/* Services */

/**
 * Renders the ArtistsLive Section component
 * @param {Object} sectionContent - Data for section
 * @param {string} lang - Language for the Section
 * @returns {ReactElement} - ArtistsLive Section Component
 */
export const ArtistsLive = ({ sectionContent, lang }) => {
  return (
    <MidContainer w="750px" >
      {sectionContent.map((secCon, i) => {
          return (
            <SectionUI
              key={i}
              sectionContent={secCon}
              region={''}
              language={lang}
            />
          );
      })}
    </MidContainer>
  );
};

export default ArtistsLive;

