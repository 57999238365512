import { Box, Flex } from '@chakra-ui/react';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import SimpleButton from '@msr/components/Buttons/SimpleButton';
import SingleLineText from '@msr/components/UI/SingleLineText';
import { render } from 'datocms-structured-text-to-html-string';

/**
 *
 * @param {Object} sectionContent
 * @returns
 */
const Benefits = ({ sectionContent, lang }) => {
  return (
    <Flex
      width="100%"
      flexWrap="wrap"
      direction={{ base: 'column', md: 'row' }}
      height="auto"
      justifyContent="center"
      p="60px 20px"
      gridColumnGap={{ md: '50px', lg: '80px' }}
      gridRowGap={{ base: '40px' }}
    >
      <Flex
        flexBasis="45%"
        alignItems="flex-end"
        justifyContent="flex-end"
        direction="column"
      >
        <Flex
          direction={{ base: 'column', md: 'row' }}
          gridGap={{
            base: '10px',
            md: 0,
            lg: '10px',
          }}
          alignItems="flex-end"
        >
          <Flex justifyContent="flex-end">
            <LazyLoadImageComponent
              alt={sectionContent[0].image?.title}
              title={sectionContent[0].image?.title}
              src={sectionContent[0].image?.url}
              mx={{ base: '0', lg: '11.5px' }}
            />
          </Flex>
          <Flex
            direction="column"
            pt={{ base: '10px', md: '45px', lg: '45px' }}
          >
            <SingleLineText
              sectionContent={sectionContent[1]}
              fontSize={{
                base: '36px',
                md: '38px',
                lg: '48px',
              }}
              color="white.50"
              pt="0"
              mt="0"
              mr="0"
              textAlign="right"
              placeContent="end"
              lineHeight="0.75"
            />
            <SingleLineText
              sectionContent={sectionContent[2]}
              fontSize={{
                base: '24px',
                md: '22px',
                lg: '28px',
              }}
              color="blue.200"
              pt="0"
              mt="0"
              mb="0"
              mr="0"
              textAlign="right"
              placeContent="end"
            />
          </Flex>
        </Flex>
        <Box
          pl={{ base: '20px', md: '10%', lg: '20%', xl: '35%', '2xl': '50%' }}
          color="blue.300"
          fontFamily="FedraSansStd-book"
        >
          <StructuredTextParser
            str={render(sectionContent[3]?.body)}
            textAlign="right"
            lang={lang}
            wordBreak="break-word"
          />
        </Box>
        <SimpleButton
          style={{
            ...sectionContent[4],
            buttonStyle: 'MSR-Orange-RightIcon',
            imgPath: '/img/right-arrow.png',
            imgWidth: '12px',
            imgPT: '3px',
          }}
          lang={lang}
          mt="40px"
          mb="0"
        />
      </Flex>

      <Flex
        flexBasis="45%"
        alignItems="flex-start"
        justifyContent="flex-end"
        direction="column"
      >
        <Flex
          direction={{ base: 'column-reverse', md: 'row' }}
          gridGap={{
            base: '10px',
            md: 0,
            lg: '10px',
          }}
        >
          <Flex direction="column" pt={{ md: '10px', lg: '0' }}>
            <SingleLineText
              sectionContent={sectionContent[6]}
              fontSize={{
                base: '36px',
                md: '38px',
                lg: '48px',
              }}
              color="white.50"
              pt="0"
              mt="0"
              ml="0"
              textAlign="left"
              placeContent="start"
              lineHeight="0.75"
            />
            <SingleLineText
              sectionContent={sectionContent[7]}
              fontSize={{
                base: '24px',
                md: '22px',
                lg: '28px',
              }}
              color="blue.200"
              pt="0"
              mt="0"
              mb="0"
              ml="0"
              textAlign="left"
              placeContent="start"
            />
          </Flex>
          <Box>
            <LazyLoadImageComponent
              alt={sectionContent[5].image?.title}
              title={sectionContent[5].image?.title}
              src={sectionContent[5].image?.url}
              mx={{ base: '0', lg: '11.5px' }}
            />
          </Box>
        </Flex>
        <Box
          pr={{ base: '20px', md: '10%', lg: '20%', xl: '35%', '2xl': '50%' }}
          color="blue.300"
          fontFamily="FedraSansStd-book"
        >
          <StructuredTextParser
            str={render(sectionContent[8]?.body)}
            textAlign="left"
            lang={lang}
          />
        </Box>
        <SimpleButton
          style={{
            ...sectionContent[9],
            buttonStyle: 'MSR-Orange-RightIcon',
            imgPath: '/img/right-arrow.png',
            imgWidth: '12px',
            imgPT: '3px',
          }}
          lang={lang}
          mt="40px"
          mb="0"
        />
      </Flex>
    </Flex>
  );
};

export default Benefits;
