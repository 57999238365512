/* Built in Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import ImageWithTextSliderGroup from '@msr/components/Sliders/ImageWithTextSliderGroup';
import ThevaramCardInfo3 from '../../components/Card/ThevarmCardInfo3';

/* Styles */

/**
 * Renders TestimonialThevaram Component
 * @param {Object} sectionContent - dato
 * @param {string} region - region of the page
 * @param {string} lang - Language of the page
 * @returns {ReactElement}  Component TestimonialThevaram
 */
export const TestimonialThevaram = ({
  sectionContent,
  myRef,
  region,
  lang,
  width,
  height,
  background,
  csrData,
}) => {
  return (
    <>
      <Box
        ref={myRef}
        // float="left"
        maxW="1440px"
        mx="auto"
        w="100%"
        p={{
          base: '65px 0px 50px 0px',
          sm: '120px 0px 50px 0px',
          md: '80px 20px',
          lg: '80px 65px',
        }}
        className="testimonial-thevaram"
      >
        <Box maxW="1106px" mx={{ base: '20px', md: 'auto' }}>
          <StructuredTextParser str={render(sectionContent[0].body)} 
            lang={lang}
          />
          <ImageWithTextSliderGroup sectionContent={sectionContent[1]} lang={lang} />
          <style>
            {`
            .testimonial-thevaram h2{
                color:white;
                text-align:center;
          font-style:normal;
          font-weight:500;
          font-size:40px;
          line-height:120%;
          color:#38281D;
          font-family:FedraSansStd-A-medium;
          margin-bottom:16px;
            }
            .testimonial-thevaram p{
              font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
text-align:center;
            }
            @media only screen and (max-width: 600px) {
              .testimonial-thevaram h2 {
    text-align:left;
    margin-bottom:14px;
  }
  .testimonial-thevaram p {
    text-align:left;
  }
}
            `}
          </style>
          {/* <Box
          // m="0 auto"
          m={{
            base: '40px auto 0 auto',
            md: '48px auto 0 auto',
            lg: '37px auto 0 auto',
          }}
          w={{ base: '100%', md: '558px' }}
          maxW="598px"
          overflow="hidden"
          p="0px 15px 30px 15px"
        >
          
          <Slider
            {...settings}
            m="0"
            mt={{ base: '40px', md: '48px', lg: '37px' }}
          >
            {sectionContent[1]?.cards?.map((data, i) => {
              return (
                <>
                  <Box
                    overflow="hidden"
                    p="0 5%"
                    key={nanoid()}
                    textAlign="center"
                    fontFamily="FedraSansStd-book"
                  >
                    {data?.thumbnail?.url && (
                      <LazyLoadImageComponent
                        src={data?.thumbnail?.url}
                        alt={data?.thumbnail?.alt}
                        title={data?.thumbnail?.title}
                        width={{ base: '100%', md: 'auto' }}
                        h={{ base: '402.69px', md: '468.54px' }}
                        verticalAlign="middle"
                        minW={{ base: '318px', md: '370px' }}
                        pb="1vw"
                      />
                    )}
                  </Box>
                </>
              );
            })}
          </Slider>

          <style>
            {`
                .slick-active p{
                  border:2px solid #38281D;
                }
            `}
          </style>
        </Box> */}
        </Box>
      </Box>
      <Box
        display="grid"
        placeItems="center
      "
      >
        <ThevaramCardInfo3
          sectionContent={sectionContent[2]}
          lang={lang}
          csrData={csrData}
        />
      </Box>
    </>
  );
};

export default TestimonialThevaram;
