/* Built in Imports */
import NextLink from 'next/link';
import { useState } from 'react';

/* External Imports */
import { Box, Flex, Heading, Link, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import SimpleButton from '@msr/components/Buttons/SimpleButton';
import MediaEmbed from '@msr/components/Embeds/MediaEmbed';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/**
 * Renders SongSection Component
 * @param {Object} sectionContent - dato
 * @param {string} region - region of the page
 * @param {string} lang - Language of the page
 * @returns {ReactElement} SongSection Component
 */
export function SongSection({
  color,
  buttonColor,
  sectionContent,
  region,
  lang,
}) {
  const [lyricsIsOpen, setLyricsIsOpen] = useState(false);
  const clickHandler = e => {
    e.preventDefault();
    setLyricsIsOpen(!lyricsIsOpen);
  };

  const embedObj = {
    embedUrl: sectionContent[0].videoUrl,
    source: 'youtube',
  };

  return (
    // </Box>
    <Box
      maxW="1440px"
      m={{ base: '0 auto' }}
      py={{ base: '60px', lg: '100px' }}
      display="grid"
      placeItems="center"
    >
      <Flex
        maxW="1230px"
        mx="auto"
        flexDir={{ base: 'column', md: 'column', lg: 'row' }}
        alignItems={{ base: 'center', md: 'flex-start' }}
        m={{ base: '0 20px', md: '0 20px', lg: '0' }}
      >
        <Box
          as="article"
          maxW="500px"
          mt={{ base: '24px', md: '0' }}
          mr={{ base: '0', md: '0', lg: '58px' }}
        >
          <Heading
            as="h3"
            fontStyle="normal"
            fontWeight="500"
            fontSize="26px"
            lineHeight="127.5%"
            color="#FFF7E9"
            fontFamily="FedraSansStd-A-medium"
          >
            {sectionContent[0]?.title}
          </Heading>
          {sectionContent[0]?.subtext && (
            <Heading
              as="h3"
              fontWeight="500"
              fontSize="26px"
              lineHeight="127.5%"
              color="#FFF7E9"
              fontFamily="FedraSerifAStdBook"
            >
              {sectionContent[0]?.subtext}
            </Heading>
          )}
          <Text
            as={
              sectionContent[0]?.description.value.document.children[0]
                .children[0].type
            }
            fontWeight="400"
            fontSize="16px"
            lineHeight="150%"
            fontStyle="normal"
            color="#FFF7E9"
            mt="10px"
            p="0"
            fontFamily="FedraSansStd-book"
            className="cardBgComponent-text"
          >
            <StructuredTextParser
              color="#FFF7E9"
              str={render(sectionContent[0].description)}
              lang={lang}
            />
          </Text>
          <style>
            {`
                .cardBgComponent-text p{
                  color:#FFF7E9;
                  font-weight: 400;
                  font-size: 16px;
                  padding:0;
                  line-height: 150%;
                  -webkit-padding-start: 0;
                  padding-inline-start:0;
                }
                .cardBgComponent-text .css-167bnn5{
                  -webkit-padding-start: 0;
                  padding-inline-start:0;
                }
                `}
          </style>
          {/* <Link> */}
          {sectionContent[0].lyrics.value.document.children.length > 1 && (<>
            <Text
              fontStyle="normal"
              fontWeight="500"
              fontSize="16px"
              lineHeight="20px"
              textDecoration="underline"
              my={{ base: '20px', md: '40px' }}
              color="#FFF7E9"
              fontFamily="FedraSansStd-medium"
              onClick={clickHandler}
              cursor="pointer"
            >
              View Lyrics
            </Text>
            {lyricsIsOpen && (
              <Box className="cardBgComponent-text">
                <StructuredTextParser
                  color="#FFF7E9"
                  str={render(sectionContent[0].lyrics)}
                />
              </Box>
            )}
          </>)}
          {/* </Link> */}
          <Box pt="19px">
            <Flex
              flexDir={{ md: 'row', base: 'column' }}
              alignItems={{ base: 'start', md: 'center' }}
            >
              {/* <Button
                  width="209px"
                  height="56px"
                  borderRadius="5px"
                  background="#FFA735"
                  border={0}
                  fontFamily="FedraSansStd-book"
                  fontWeight="600"
                  fontSize="16px"
                  lineHeight="20px"
                  color={buttonColor}
                >
                  Download Full Album
                </Button> */}
                <SimpleButton
                  // bType={sectionContent}
                  style={sectionContent[0]?.cta[0]}
                  width="209px"
                  height="56px"
                  borderRadius="5px"
                  background="#FFA735"
                  border={0}
                  fontFamily="FedraSansStd-book"
                  fontSize="16px"
                  lineHeight="20px"
                  region={region}
                  lang={lang}
                  mb="0"
                  mt="0"
                />
                <Flex height="56px" maxH="46px" mt={{ base: '20px', md: 0 }}>
                  {sectionContent[0].musicAvailableIn.map((musicLink, index) => {
                    let url = '';
                    if (musicLink.source == 'sadhguruapp') {
                      url =
                        `${config.staticPath}/d/46272/1694845279-sadhguru-app.png`;
                    } else if (musicLink.source == 'applemusic') {
                      url =
                        // 'https://images.sadhguru.org/mahashivratri/wp-content/uploads/2020/02/itunes-copy.png';
                        `${config.staticPath}/d/46272/1666672470-itunes-white.png`;
                    } else if (musicLink.source == 'jiosaavn') {
                      url =
                        // 'https://images.sadhguru.org/mahashivratri/wp-content/uploads/2020/01/jio-white.png';
                        `${config.staticPath}/d/46272/1670055813-jio-white.png`;
                    } else if (musicLink.source == 'youtube') {
                      url =
                        `${config.staticPath}/d/46272/1681113091-youtube-squared.png`;
                    } else {
                      url =
                        // 'https://images.sadhguru.org/mahashivratri/wp-content/uploads/2020/02/spotify-1.png';
                        `${config.staticPath}/d/46272/1670055807-spotify-1.png`;
                    }
                    return (
                      <NextLink href={musicLink.embedUrl} passHref key={index} legacyBehavior>
                        <Link>
                          <LazyLoadImageComponent
                            ml={{ base: '0', sm: '33.63px', md: '33.63px' }}
                            mr={{ base: '33.63px', sm: '0', md: '0' }}
                            maxH="43px"
                            src={url}
                          />
                        </Link>
                      </NextLink>
                    );
                  })}
                  {/* <LazyLoadImageComponent
                      ml={{ base: 0, md: '33.63px' }}
                      maxH="43px"
                      src="https://images.sadhguru.org/mahashivratri/wp-content/uploads/2020/02/itunes-copy.png"
                    />
                    <LazyLoadImageComponent
                      ml="33.63px"
                      maxH="43px"
                      src="https://images.sadhguru.org/mahashivratri/wp-content/uploads/2020/02/spotify-1.png"
                    />
                    <LazyLoadImageComponent
                      ml="33.63px"
                      maxH="43px"
                      src="https://images.sadhguru.org/mahashivratri/wp-content/uploads/2020/01/jio-white.png"
                    /> */}
                </Flex>
            </Flex>
          </Box>
        </Box>
        <Box
          pt={{ base: '30.01px', md: '63px', lg: '0' }}
          width={{ base: '100%', md: '579px' }}
        >
          <MediaEmbed
            sectionContent={embedObj}
            margin="0"
            // width={{ base: '100%', md: '579px' }}
          />
          {/* <Box
              as="iframe"
              width={{ base: '100%', md: '100%', lg: '579px' }}
              height="315"
              src={sectionContent[0].videoUrl}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            /> */}
        </Box>
      </Flex>
    </Box>
  );
}

export default SongSection;
