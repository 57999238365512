/* Built In Imports */
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

/* External Imports */
import {
    Box,
    Button,
    Flex,
    Modal,
    ModalContent,
    ModalOverlay,
    Radio,
    RadioGroup,
    Table,
    Tbody,
    Td,
    Text,
    Tr,
    useDisclosure
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import MsrSectionTitle from '@msr/components/Headings/MsrSectionTitle';
import SubscriptionModal from '@msr/components/Modal/SubscriptionModal';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/* Services */

/**
 * @param root0
 * @param root0.sectionContent
 * @param root0.lang
 * @returns
 */
const SadhanaKitSection = ({ sectionContent, lang }) => {
  const [startDate, setStartDate] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = useRef(null);
  const [messageTemp, setMessageTemp] = useState('');
  const router = useRouter();

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  /**
   *
   * @param num
   */
  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  /**
   *
   * @param date
   */
  function formatDate(date) {
    date = new Date(new Date(date).toLocaleString('en-US', {timeZone: 'Asia/Kolkata'}));
    return [padTo2Digits(date.getDate()), monthNames[date.getMonth()]].join(
      ' '
    );
  }

  const clickHandler = () => {
    onOpen();
    // window.history.replaceState({ ...window.history.state, as: `${router.asPath}?popup=1`, url: `${router.asPath}?popup=1` }, '', `${router.asPath}?popup=1`);
    const popupUrl =
      router.asPath.indexOf('?popup=1') > -1
        ? router.asPath
        : router.asPath.indexOf('?') > -1
        ? `${router.asPath}&popup=1`
        : `${router.asPath}?popup=1`;
    window.history.pushState(window.history.state, '', popupUrl);
    window['loc'] = popupUrl;
  };

  useEffect(() => {
    if (!isOpen && window?.loc?.indexOf('popup=1') > -1) {
      const parentUrl =
        location.origin +
        location.pathname +
        location.search.replace(/[\?&]popup=1/, '').replace(/^&/, '?');
      window.history.pushState(window.history.state, '', parentUrl);
    }
  }, [isOpen]);

  useEffect(() => {
    window.onpopstate = e => {
      // consoleLog('onpopstate', router, window, e);
      if (window?.loc?.indexOf('popup=1') > -1) {
        // consoleLog('onpopstate return', router.asPath);
        onClose();
        window['loc'] = router.asPath;
      }
    };
  }, [router.asPath]);

  return (
    <Flex bgColor="#03062C" py={{ base: '34px', md: '60px' }} px="16px">
      <Box
        maxW="770px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems={{ base: 'left', md: 'center' }}
        textAlign={{ base: 'left', md: 'center' }}
        mx="auto"
      >
        <MsrSectionTitle titleObj={sectionContent[0]} as="h1" />

        <StructuredTextParser
          str={render(sectionContent[1]?.body)}
          region={''}
          lang={lang}
          tAlign={sectionContent[1].style}
          theme="light"
        />

        <Flex
          gridGap={{ base: '16px', md: '20px' }}
          my="40px"
          ref={finalRef}
          mx="auto"
          className="customTable"
          width="full"
        >
          <RadioGroup onChange={setStartDate} value={startDate} width="100%">
            <Table variant="whiteAlpha" bgColor="#FFF7E9">
              <Tbody fontFamily="FedraSansStd-book">
                {sectionContent[2]?.data &&
                  Object.keys(sectionContent[2].data).map((item, index) => {
                    const col2 =sectionContent[2]?.data[item]?.split('|')
                    return (
                      <Tr
                        key={index}
                        border="1px solid rgb(221 221 221)"
                        p="0.35em"
                        fontSize="18px"
                        fontWeight={index > 0 ? '' : '600'}
                      >
                        <Td>
                          {index > 0 ? (
                            <Radio
                              name="day"
                              id="radio"
                              className="radio"
                              colorScheme="orange"
                              value={col2[0]}
                              outline="1px solid #CF4520"
                              width="auto"
                              height="26px"
                              key={col2[0]}
                              cursor="pointer"
                              border={0}
                              verticalAlign="text-top"
                            >
                              {item}
                            </Radio>
                          ) : (
                            <>{item}</>
                          )}
                        </Td>
                        <Td borderLeft="1px solid rgb(221 221 221)">
                          {index > 0
                            ? col2[1] ? col2[1] : formatDate(col2[0])
                            : col2[1] || col2[0]}
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </RadioGroup>
        </Flex>

        <Button
          className="orange-button"
          mb={'30px'}
          display="block"
          fontWeight={'500'}
          fontFamily={'FedraSansStd-medium'}
          fontSize={'16px'}
          bgColor="yellow.100"
          h={'auto'}
          minH="50px"
          // minW="200px"
          textAlign="center"
          padding={'18px 20px'}
          borderRadius="5px"
          color="black"
          textDecoration="none"
          width={'auto'}
          _hover={{
            bg: 'fulvous',
          }}
          onClick={() => clickHandler()}
          disabled={!startDate}
        >
          <Flex justifyContent="center" alignItems="center">
            <Text pr={'5px'}>{sectionContent[3]?.buttonText}</Text>
          </Flex>
        </Button>

        <Modal
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={onClose}
          isCentered
          // motionPreset="slideInBottom"
          size={!messageTemp ? 'xl' : '4xl'}
          // blockScrollOnMount={false}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent>
            <SubscriptionModal
              sectionContent={sectionContent[4]}
              messageT={messageTemp}
              newsletters={{ MSRSadhana: 'MSRSadhana' }}
              dataLayer={{
                event: 'msr_vp_pushed',
                virtual_path: '/msr_sadhana_vp_success',
              }}
              date={startDate}
            />
          </ModalContent>
        </Modal>
      </Box>
      <style jsx global>{`
        .customTable table {
          background: #fff7e9;
          border-radius: 3px;
        }
        .customTable table tr:first-child {
          font-family: 'FedraSansStd-A-medium';
          font-weight: 500;
          background: #fff7e9 !important;
        }
        .customTable table tr,
        .customTable table tr td {
          border: none;
          padding-left: 100px;
        }
        .customTable table tr:nth-of-type(odd) {
          background: #ebe3d5;
        }
        .customTable table tr:last-child {
          border-radius: 3px;
        }
        @media (max-width: 599px) {
          .customTable table tr td {
            border: none;
            padding-left: 20px;
          }
        }
      `}</style>
    </Flex>
  );
};

export default SadhanaKitSection;
