/* Built In Imports */
import { useContext } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */

/* Services */
import { RegionContext } from '@msr/store/region-context';

// Utils
import { canRenderContent } from '@msr/components/Utility/utils';

/**
 * Renders the Style Config on sectionId
 * @param {Object} StyleConfig- Data for section
 * @param {Object} children - Child Elements
 * @param {string} id - id
 * @returns {ReactElement} Designs style config for children component
 */
const StyleConfig = ({
  id,
  children,
  style,
  section,
  bottomAngled,
  region,
  width,
  ...props
}) => {
  const currRegion = useContext(RegionContext);
  const secRegion = section?.region?.toLowerCase();
  const renderContent = canRenderContent(
    currRegion,
    secRegion,
    section?.sectionContent
  );
  const ignoreComponents = ['msr_section_title'];

  // consoleLog('children', style?._modelApiKey);

  return (
    renderContent &&
    (style && style?._modelApiKey && Object.keys(style)?.length > 0 ? (
      <Box
        // ? implement image_repeat_type property
        id={id}
        w={width}
        clipPath={
          style?.angledSection
            ? `polygon(0 2%, 100% 0, 100% 100%, 0% 100%)`
            : bottomAngled
            ? `polygon(0 0, 100% 0, 100% 100%, 0% 98%)`
            : 'unset'
        }
        // padding="60px"
        pt={{
          base: style?.angledSection
            ? '104px'
            : props.pT || props.pT?.base || '0',
          md: style?.angledSection ? '80px' : props.pT || props.pT?.md || '0',
        }}
        pb={props.pB || '0'}
        mb={style?.additionalBottomPadding ? props.mB || '0' : '0'}
        bgColor={style?.bgColour?.hex || null}
        bgImage={style?.bgImage?.url || null}
        display="flex"
        bgRepeat="none"
        flexDirection="column"
        bgSize="cover"
        {...props}
      >
        {children}
      </Box>
    ) : (
      <Box id={id}>{children}</Box>
    ))
  );
};

export default StyleConfig;
