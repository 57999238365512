/* Built In Imports */
/* External Imports */
import { Box, Heading, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import SimpleButton from '@msr/components/Buttons/SimpleButton';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/* Services */

/**
 * Renders the Attend In Person Banner Section component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region for the Section
 * @param {string} lang - Language for the Section
 * @returns {ReactElement} - AttendInPersonBanner Section Component
 */

export const AttendInPersonBanner = ({ section, lang }) => {
  // consoleLog('AttendInPersonBanner', section);
  return (
    <Box
      backgroundImage={{
        base: section.sectionContent[0]?.mobileImage?.url,
        sm: section.sectionContent[0]?.image?.url,
      }}
      bgSize={{ base: 'contain', sm: 'cover', xl: 'contain' }}
      bgColor="#000"
      bgPos={{ base: 'top', lg: '45px center', '2xl': '70% center' }}
      bgRepeat="no-repeat"
      width="100%"
      position="relative"
      height={{ base: 'auto', sm: '663px' }}
      display="flex"
      flexDir="column"
      justifyContent="center"
      _before={{
        lg: {
          position: 'absolute',
          height: '100%',
          width: '50%',
          zIndex: 0,
          content: '""',
          background:
            'linear-gradient(90deg, #000000 43.45%, rgba(0, 0, 0, 0) 100%)',
        },
      }}
      _after={{
        lg: {
          position: 'absolute',
          height: '100%',
          width: '20%',
          zIndex: 0,
          right: 0,
          content: '""',
          background:
            'linear-gradient(270deg, #000000 43.45%, rgba(0, 0, 0, 0) 100%)',
        },
        sm: {
          position: 'absolute',
          height: '100%',
          // width: '30%',
          zIndex: 0,
          right: 0,
          content: '""',
          background:
            'linear-gradient(270deg, #000000 43.45%, rgba(0, 0, 0, 0) 100%)',
        },
      }}
    >
      <Box className="page-wrap" maxW="1140px">
        <Box
          display={{ base: 'block', md: 'none' }}
          // backgroundImage={{
          //   base: `${config.imgPath}/d/46272/1663039969-attend-in-person-mbe.jpg`,
          //   sm: 'none',
          // }}
          position="relative"
          height={{ base: '328px', sm: 'auto' }}
          backgroundSize="cover"
          backgroundPosition="center"
          _after={{
            base: {
              /*  background: 'linear-gradient(360deg, black, transparent)',
              position: 'absolute', */
              width: '100%',
              height: '114px',
              left: '0',
              top: '214px',
              content: '" "',
              zIndex: 2,
            },
            sm: {
              background: 'none !important',
            },
          }}
        />
        <Box
          maxW={{ base: '100%', sm: '56%', md: '420px', lg:'460px' }}
          mx={{ base: 'auto', sm: '20px', md: 'initial' }}
          position="relative"
          zIndex={1}
          ml={{ base: 'auto', md: '15px', lg: '125px' }}
        >
          <Heading
            as="h1"
            mb={{ base: '10px', md: '30px' }}
            fontFamily="FedraSansStd-A-medium"
            fontStyle="normal"
            fontWeight="500"
            fontSize={
              lang == 'ta'
                ? { base: '26px', md: '30px' }
                : { base: '26px', md: '36px', lg:'40px' }
            }
            color="#FFF7E9"
          >
            {section?.sectionContent[1]?.text}
          </Heading>

          <Heading
            as="h4"
            fontFamily="FedraSansStd-medium"
            fontStyle="normal"
            fontWeight="500"
            fontSize={
              lang == 'ta'
                ? { base: '20px', md: '18px' }
                : { base: '20px', md: '18px' }
            }
            color="#FFA735"
            mb={{ base: '20px', md: '30px' }}
          >
            {section?.sectionContent[2]?.text} <br />
            {section?.sectionContent[3]?.text}
          </Heading>
          <Text
            as="p"
            fontFamily="FedraSansStd-book"
            fontStyle="normal"
            fontWeight={{ base: '400', md: '500' }}
            color="#D6D6D6"
            mb={{ base: '40px', md: '30px' }}
            maxW={{ base: '100%', md: '70%' }}
            sx={{
              p: {
                fontSize: lang == 'ta' ? { base: '14px' } : { base: '16px' },
              },
            }}
          >
            <StructuredTextParser
              str={render(section?.sectionContent[4]?.body)}
              theme="light"
            />
          </Text>
          {/* <SimpleButton
            bg="#FFA735"
            color="#000"
            fontSize="16px"
            fontFamily="FedraSansStd-book"
            mb="10px"
            padding=" 18px 20px"
            display="flex"
            flexDirection="column"
            alignItems=" flex-start"
            height="56px"
            _hover={{
              backgroundColor: 'none',
            }}
          >
            {section?.sectionContent[5]?.buttonText}
          </SimpleButton> */}
          <SimpleButton
            style={section?.sectionContent[5]}
            region={''}
            lang={lang}
            mb="10px"
          />
          <Text
            as="p"
            fontFamily="FedraSansStd-book"
            fontStyle="italic"
            fontWeight=" 500"
            fontSize="16px"
            color="#fff"
            mb="30px"
            sx={{
              fontSize: lang == 'ta' ? { base: '14px' } : { base: '16px' },
            }}
          >
            {section?.sectionContent[6]?.text}
          </Text>

          <Text
            as="p"
            fontFamily="FedraSansStd-book"
            fontStyle="normal"
            fontWeight=" 500"
            fontSize="16px"
            color="#fff"
            maxW={{ base: '100%', md: '70%' }}
            pb={{ base: '38px', md: 'inherit' }}
          >
            {section?.sectionContent[7]?.text}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
